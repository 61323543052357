import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../app/guard/auth.guard';
import { ResetPasswordGuard } from './guard/reset-password.guard';

import { LoginComponent } from './components/core/login/login.component';
import { ResetPasswordComponent } from './components/core/reset-password/reset-password.component';
import { RentFeatureGuard } from './guard/rentFeature.guard';




const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    component: LoginComponent
  },
  {
    path: 'reset',
    component: ResetPasswordComponent,
    canActivate: [ResetPasswordGuard]
  },

  {
    path: 'admin',
    loadChildren: () => import('../app/module/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'rentalStation',
    loadChildren: () => import('../app/module/rental-station/rental-station.module').then(m => m.RentalStationModule),
    canActivate: [AuthGuard, RentFeatureGuard]
  },

  {
    path: 'marketingUser',
    loadChildren: () => import('../app/module/marketing/marketing.module').then(m => m.MarketingModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({

  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
