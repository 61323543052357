import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IFacility } from '../../interface/facility';
import { IPoi } from '../../interface/poi';

@Injectable({
  providedIn: 'root'
})
export class PoiService {

  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootPoi;
  }

  getAtlas(poitype: string, poiname: string): Observable<any> {
    let parameters = '';

    if (poiname) {
      parameters = !parameters.length ? `?poiname=${poiname}` : `?poitype=${poitype}&poiname=${poiname}`;
    }

    if (poitype) {
      parameters = !parameters.length ? `?poitype=${poitype}` : `?poitype=${poitype}&poiname=${poiname}`;
    }
    return this.httpClient.get<any>(this.url + 'atlas' + parameters);
  }

  getMyFacilities(pageNumber: number, pageSize: number): Observable<any> {
    const parameters = '?pageNumber=' + pageNumber + '&pageSize=' + pageSize;
    return this.httpClient.get<any>(this.url + 'myfacilities' + parameters);
  }

  aroundMe(IdFacility: number) {
    const parameters = '?IdFacility=' + IdFacility;
    return this.httpClient.get<any>(this.url + 'aroundme' + parameters);
  }

  facilityUpsert(facility: IFacility) {
    return this.httpClient.post(this.url + 'facility-upsert', facility);
  }

  getFacilities(type: string, pageNumber: number, pageSize: number, sort: number, order: boolean): Observable<any> {
    if (type != null) {
      const parameters = '?facilitytype=' + type + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&sort=' + sort + '&order=' + order;
      return this.httpClient.get<any>(this.url + 'facilities' + parameters);


    } else {
      return this.httpClient.get<any>(this.url + 'facilities');
    }
  }

  getFacilityDetails(idFacility: number): Observable<IFacility> {
    const parameters = '?idFacility=' + idFacility;
    return this.httpClient.get<IFacility>(this.url + 'facility-details' + parameters);
  }

  deleteFacility(idFacility: number) {
    const parameters = '?idFacility=' + idFacility;
    return this.httpClient.delete(this.url + 'facility-delete' + parameters);
  }

  disableFacility(idFacility: number, accessToken: string) {
    const body = {
      idFacility: idFacility
    };
    return this.httpClient.post(this.url + 'facility-disable', body);
  }

  upsertTechnicalPoi(poi: any) {
    return this.httpClient.post(this.url + 'upserttechnicalpoi', poi);
  }

  upsertTouristPoi(poi: any) {
    return this.httpClient.post(this.url + 'upserttouristpoi', poi);
  }

  deletePoi(idPoi: number) {
    const parameters: string = '?IdPoi=' + idPoi;
    return this.httpClient.delete(this.url + 'deletepoi' + parameters);
  }

  upsertPoiSheet(poiSheet: any) {
    const url = environment.protocol + environment.apiBaseUrl + environment.rootTouristPoi;
    return this.httpClient.post(`${this.url}upsertpoisheet`, poiSheet);
  }

  getpoisheet(poiId: number) {
    const url = environment.protocol + environment.apiBaseUrl + environment.rootTouristPoi;
    return this.httpClient.get(`${this.url}getpoisheet?poiId=${poiId}`);
  }

  uploadMediaFile(file: any, section: string) {
    const url = environment.protocol + environment.apiBaseUrl
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post(`${url}/uploadmedia?section=${section}`, formData, { responseType: 'text' });
  }

  downloadMediaFile(fileId: number) {
    const url = environment.protocol + environment.apiBaseUrl;
    return this.httpClient.get(`${url}/downloadmedia?fileId=${fileId}`, { responseType: 'blob' });
  }

  getDetailpoi(IdPoi: any): Observable<IPoi> {
    const parameters: string = '?IdPoi=' + IdPoi;
    return this.httpClient.get<IPoi>(this.url + 'detailpoi' + parameters);
  }





}
