import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { PoiService } from '../../../services/POI/poi.service';
import { PromoService } from '../../../services/promo/promo.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { SnackBarSuccessComponent } from '../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { IFacility } from '../../../interface/facility';
import { IPromo } from '../../../interface/promo';
import { DomSanitizer } from '@angular/platform-browser';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  }
}


@Component({
  selector: 'app-edit-promotion-dialog',
  templateUrl: './edit-promotion-dialog.component.html',
  styleUrls: ['./edit-promotion-dialog.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class EditPromotionDialogComponent implements OnInit {

  idPromo: number;
  promo: IPromo;

  languageForm: FormGroup;
  informationForm: FormGroup;
  facilitiesForm: FormGroup;
  currentDate: Date = new Date();
  minDate: Date = new Date(this.currentDate.getFullYear(), this.currentDate.getUTCMonth(), this.currentDate.getUTCDate());
  minDateEnd: Date;
  facilities: Array<any> = [];
  facilitiesSelect: Array<any> = [];

  image: any;
  currentImage: any;
  file: File;
  fileName: string;
  attachments: Array<any> = [];
  currentAttachments: any;

  leftTimeMinList: Array<any> = [
    { min: 15, value: '15min' },
    { min: 30, value: '30min' },
    { min: 45, value: '45min' },
    { min: 60, value: '1h' },
    { min: 90, value: '1h:30min' },
    { min: 120, value: '2h' },
    { min: 240, value: '4h' },
    { min: 360, value: '6h' },
    { min: 720, value: '12h' },
    { min: 1440, value: '24h' }
  ];

  radiusList: Array<any> = [
    { radius: 10, value: '10m' },
    { radius: 20, value: '20m' },
    { radius: 50, value: '50m' }
  ];

  constructor(
    private dialogRef: MatDialogRef<EditPromotionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private POIService: PoiService,
    private promoService: PromoService,
    private matSnackBar: MatSnackBar
  ) {
    this.idPromo = data;
  }


  ngOnInit() {
    this.getFacilities();
    this.getCurrentPromotion();
  }

  getCurrentPromotion() {
    this.promoService.promoDetail(this.idPromo).subscribe(promo => {
      this.promo = promo;
      this.initForms(promo);
      if (this.promo.attachments.length) {
        this.getPromoAttachments(this.promo.attachments[0].id);
      }
    });
  }

  fileChangeEvent(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.currentImage = null;
      this.currentAttachments = null;
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => { this.image = event.target.result; };
      this.file = event.target.files[0];
      this.POIService.uploadMediaFile(this.file, 'Promo').subscribe(res => {
        this.attachments = [{
          id: 0,
          fileName: res,
          type: 'Image',
          langInfos: []
        }];
      });
    }
  }

  getPromoAttachments(idAttachments: number) {
    this.POIService.downloadMediaFile(idAttachments).subscribe(res => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.currentImage = reader.result;
      }, false);

      if (res) {
        reader.readAsDataURL(res);
      }
    });
  }

  initForms(promo: IPromo) {
    this.languageForm = this.formBuilder.group({
      language: [''],
      infos: this.formBuilder.array([])
    });

    this.checkInfoLanguageForm(promo.langInfos);


    this.informationForm = this.formBuilder.group({
      start: [promo.start, Validators.required],
      end: [promo.end, Validators.required],
      leftTimeMin: [promo.leftTimeMin, Validators.required],
      disabled: [promo.disabled],
      url: [promo.url, [Validators.pattern('^(ftp|http|https):\/\/[^ "]+$')]],
      radius: [promo.radius],
      showcaseEnd: [promo.showcaseEnd]
    });

    this.facilitiesForm = this.formBuilder.group({
      facility: [null],
      infoFacilties: this.formBuilder.array([])
    });

    this.checkInfosFacilities(promo.facilities);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // Form Language
  get infosForms() {
    return this.languageForm.get('infos') as FormArray;
  }

  checkInfoLanguageForm(langInfos: Array<any>) {
    langInfos.forEach(currentInfo => {
      const info = this.formBuilder.group({
        languageCode: [currentInfo.languageCode],
        title: [currentInfo.title, Validators.required],
        description: [currentInfo.description, Validators.required],
        termsOfUse: [currentInfo.termsOfUse, Validators.required],
      });
      this.infosForms.push(info);
    });
  }

  onChangeLanguages(language: string) {
    const info = this.formBuilder.group({
      languageCode: [language],
      title: [null, Validators.required],
      description: [null],
      termsOfUse: [null, Validators.required],
    });

    if (this.infosForms.length > 0) {
      this.checkIfInfoExist(info);
    }
    else {
      this.infosForms.push(info);
    }
  }

  checkIfInfoExist(info: any) {
    let find: boolean;

    this.infosForms.controls.forEach((item) => {
      if (item.get('languageCode').value === info.get('languageCode').value) {
        find = true;
        return;
      }
    });

    if (!find) {
      this.infosForms.push(info);
    }
  }

  removeLanguage(index: number) {
    this.infosForms.removeAt(index);
    this.languageForm.get('language').reset();
  }

  getLanguageInfoValue() {
    if (this.languageForm.invalid) {
      return;
    }
  }
  // End Form Language

  // Form information
  getInformationValue() {
    if (this.informationForm.invalid) {
      return;
    }
  }
  // End Form information

  // Facility associated
  getFacilities() {
    this.POIService.getFacilities('Market', 0, 0, 0, false).subscribe(res => {
      this.fillFacilities(res.result);
    }, error => {
    });
  }

  fillFacilities(facilities: Array<IFacility>) {
    facilities.forEach(facility => {
      this.facilities.push({ id: facility.id, name: facility.langInfos[0].name });
    });
  }

  get infoFacilities() {
    return this.facilitiesForm.get('infoFacilties') as FormArray;
  }

  checkInfosFacilities(facilities: Array<any>) {
    facilities.forEach(facility => {
      const infoFacility = this.formBuilder.group({
        id: [facility.id],
        name: [facility.langInfos[0].name],
        radius: [facility.radius]
      });
      this.infoFacilities.push(infoFacility);
    });
  }

  selectFacility(facility: any) {
    const infoFacility = this.formBuilder.group({
      id: [facility.id],
      name: [facility.name],
      radius: [this.informationForm.get('radius').value]
    });

    if (this.infoFacilities.length > 0) {
      this.checkFacilityExist(infoFacility);
    } else {
      this.infoFacilities.push(infoFacility);
    }
    this.facilitiesForm.get('facility').setValue(null);
  }

  checkFacilityExist(currentFacility: any) {
    let find: boolean;

    this.infoFacilities.controls.forEach((facility) => {
      if (facility.get('id').value === currentFacility.get('id').value) {
        find = true;
        return;
      }
    });

    if (!find) {
      this.infoFacilities.push(currentFacility);
    }
  }

  removeFacility(index: number) {
    this.infoFacilities.removeAt(index);
  }

  getFacilitiesValue() {
    if (this.facilitiesForm.invalid) {
      return;
    }

    if (this.infoFacilities.length > 0) {
      this.infoFacilities.controls.forEach(item => {
        this.facilitiesSelect.push({ idStruttura: item.get('id').value, radiusStruttura: item.get('radius').value });
      });
    }
    this.upsertPromo();
  }
  // End facility associated

  upsertPromo() {
    const promo = {
      strutture: this.facilitiesSelect,
      langInfos: this.languageForm.value.infos,
      start: this.informationForm.value.start,
      end: this.informationForm.value.end,
      leftTimeMin: this.informationForm.value.leftTimeMin,
      id: this.promo.id,
      disabled: this.informationForm.value.disabled,
      url: this.informationForm.value.url,
      radius: this.informationForm.value.radius,
      showcaseEnd: this.informationForm.value.showcaseEnd,
      attachments: !this.attachments.length ? this.promo.attachments : this.attachments
    };

    this.promoService.promoUpsert(promo).subscribe(res => {
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Promozione modificata con successo',
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.closeDialog();
    }, error => {});
  }


}
