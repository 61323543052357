import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth/auth.service';
import { IUser } from '../../interface/user';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { PoiService } from '../../services/POI/poi.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss']
})
export class ProfileDialogComponent implements OnInit {

  currentImage: any;
  isLoading: boolean;
  user$: Observable<IUser>;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private dialogRef: MatDialogRef<ProfileDialogComponent>,
    private POIService: PoiService,
  ) { }



  ngOnInit() {
    this.getProfile();
    this.getImageProfile();
  }

  getProfile() {
    this.user$ = this.authService.getUser();
  }

  getImageProfile() {
    this.authService.getUser().pipe(
      switchMap(profile => this.POIService.downloadMediaFile(Number(profile.profileImage)).pipe(
        tap(res => {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            this.currentImage = reader.result;
          }, false);

          if (res) {
            reader.readAsDataURL(res);
          }
        })
      ))
    ).subscribe();
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
