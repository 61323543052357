import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { PoiService } from '../../../services/POI/poi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { TranslateService } from '@ngx-translate/core';
import { IFacility } from '../../../interface/facility';
import { IFeature } from '../../../interface/feature';
import { MapboxService } from '../../../services/mapbox/mapbox.service';
import { switchMap, tap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingService } from '../../../services/loading/loading.service';
import { SpaceValidation } from '../../../validators/space-validator';


@Component({
  selector: 'app-edit-facility-dialog',
  templateUrl: './edit-facility-dialog.component.html',
  styleUrls: ['./edit-facility-dialog.component.scss']
})
export class EditFacilityDialogComponent implements OnInit, OnDestroy {

  type: string;
  idFacility: number;
  isLoading: boolean;
  isLoadingSubscription: Subscription;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  facility: IFacility;
  currentFacility: IFacility;
  isLinear = false;
  feature: IFeature;
  feautures: Array<IFeature> = [];
  lat: any;
  lng: any;

  image: any;
  currentImage: any;
  file: File;
  fileName: string;
  poiSheet: any;
  currentPoiSheet: any;


  constructor(
    private dialogRef: MatDialogRef<EditFacilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private POIService: PoiService,
    private mapboxService: MapboxService,
    private translateService: TranslateService,
    private matSnackBar: MatSnackBar,
    private loadingService: LoadingService,
    private spaceValidation: SpaceValidation
  ) {
    this.type = data.type;
    this.idFacility = data.id;
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }


  ngOnInit() {
    this.getCurrentFacility();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getCurrentFacility() {
    this.isLoading = true;
    this.POIService.getFacilityDetails(this.idFacility).subscribe(res => {
      this.isLoading = false;
      this.currentFacility = res;
      this.getPoisheet();
      this.checkFirstForm();
      this.checkInfosForm(res);
      this.checkSecondForm(res);
    });
  }


  fileChangeEvent(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.currentImage = null;
      this.currentPoiSheet = null;
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => { this.image = event.target.result; };
      this.file = event.target.files[0];
      this.POIService.uploadMediaFile(this.file, 'Geolocation').subscribe(res => {
        this.poiSheet = {
          poiId: this.idFacility,
          sheet: {
            id: 0,
            attachments: [{
              id: 0,
              fileName: res,
              type: 'Image',
              langInfos: []
            }],
            langInfos: []
          }
        };
      });
    }
  }

  getPoisheet() {
    this.POIService.getpoisheet(this.idFacility).pipe(
      tap(res => this.currentPoiSheet = res),
      switchMap((res: any) => res.sheet ? this.POIService.downloadMediaFile(res.sheet.attachments[0].id) : of(null))
    ).subscribe(res => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.currentImage = reader.result;
      }, false);

      if (res) {
        reader.readAsDataURL(res);
      }
    });
  }

  checkFirstForm() {
    this.firstFormGroup = this.formBuilder.group({
      language: [null],
      infos: this.formBuilder.array([])
    });
  }

  get f() { return this.firstFormGroup.controls; }

  get infosForms() {
    return this.firstFormGroup.get('infos') as FormArray;
  }

  checkInfosForm(facility: IFacility) {
    facility.langInfos.forEach((item) => {
      const info = this.formBuilder.group({
        languageCode: [item.languageCode],
        name: [item.name, [Validators.required, this.spaceValidation.checkSpaceValidation]],
        address: [item.address, [Validators.required, this.spaceValidation.checkSpaceValidation]]
      });
      this.infosForms.push(info);
    });
  }

  onChangeLanguages(language: string) {
    const info = this.formBuilder.group({
      languageCode: [language],
      name: [null, Validators.required],
      address: [null, Validators.required]
    });

    if (this.infosForms.length > 0) {
      this.checkIfInfoExist(info);
    }

    else {
      this.infosForms.push(info);
    }
  }

  checkIfInfoExist(info: any) {
    let find: boolean;

    this.infosForms.controls.forEach((item) => {
      if (item.get('languageCode').value === info.get('languageCode').value) {
        find = true;
        return;
      }
    });

    if (!find) {
      this.infosForms.push(info);
    }
  }

  removeLanguage(index: number) {
    this.infosForms.removeAt(index);
    this.firstFormGroup.get('language').reset();
  }

  // Search place
  search(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    if (searchTerm && searchTerm.length >= 3) {
      this.feature = null;
      this.mapboxService.searchPoi(searchTerm).subscribe(res => {
        this.feautures = [];
        res.map(feature => {
          this.feautures.push({
            place_name: feature.place_name,
            city: '',
            latitude: feature.geometry.coordinates[1],
            longitude: feature.geometry.coordinates[0]
          });
        });
      });
    } else {
      this.feautures = [];
    }
  }

  // Select place
  addressesSelected(optionSelected: string) {
    const address: string = optionSelected;
    this.feautures.forEach(feature => {
      if (feature.place_name === address) {
        this.feature = feature;
      }
    });
    this.lat = this.feature.latitude;
    this.lng = this.feature.longitude;
    this.secondFormGroup.get('latitude').setValue(this.lat);
    this.secondFormGroup.get('longitude').setValue(this.lng);
  }

  checkSecondForm(facility: IFacility) {
    if (facility) {
      this.secondFormGroup = this.formBuilder.group({
        id: [facility.id],
        email: [facility.email, Validators.email],
        latitude: [facility.latitude, Validators.required],
        longitude: [facility.longitude, Validators.required],
        city: [facility.city],
        phone: [facility.phone, [Validators.pattern('^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\\\s\\\\./0-9]*$')]],
        uniqueCode: [facility.uniqueCode, /*[Validators.pattern('^[a-zA-Z0-9]{0,7}$')]*/ Validators.required],
        maxPromoCount: [facility.maxPromoCount, [Validators.pattern('^[0-9.]+$')]],
        disabled: [facility.disabled],
        vat: [facility.vat, [Validators.pattern('(IT)?[0-9]{11}')]]
      });
    }

  }

  onSubmit() {
    if (this.secondFormGroup.invalid || this.firstFormGroup.invalid) {
      return;
    }

    this.facility = {
      email: this.secondFormGroup.value.email,
      phone: this.secondFormGroup.value.phone,
      vat: this.secondFormGroup.value.vat,
      uniqueCode: this.secondFormGroup.value.uniqueCode,
      maxPromoCount: this.secondFormGroup.value.maxPromoCount,
      id: this.secondFormGroup.value.id,
      latitude: this.secondFormGroup.value.latitude,
      longitude: this.secondFormGroup.value.longitude,
      city: this.secondFormGroup.value.city,
      type: this.type,
      disabled: this.secondFormGroup.value.disabled,
      langInfos: this.firstFormGroup.value.infos,
      actionARadius: 0,
      tolerance: 0
    };

    this.facilityUpsert(this.facility);
  }

  facilityUpsert(facility: IFacility) {
    this.isLoading = true;
    if (this.poiSheet) {
      this.POIService.facilityUpsert(facility).pipe(
        switchMap(res => this.POIService.upsertPoiSheet(this.poiSheet))
      ).subscribe(res => {
        this.isLoading = false;
        this.translateService.get('FacilityEdit_200').subscribe(res =>
          this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
            data: res.title,
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
          }));
        this.closeDialog();
      });
    } else {
      this.POIService.facilityUpsert(facility).subscribe(res => {
        this.isLoading = false;
        this.translateService.get('FacilityEdit_200').subscribe(res =>
          this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
            data: res.title,
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
          }));
        this.closeDialog();
      });
    }
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
