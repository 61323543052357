import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IPoiCategory } from '../../interface/poi-category';

@Injectable({
  providedIn: 'root'
})
export class PoiPropertyService {
  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootPoiProperty;
  }

  getCategories(filter: string): Observable<any> {
    if (filter == null) {
      return this.httpClient.get<any>(this.url + 'categories');
    } else {
      return this.httpClient.get<any>(this.url + `categories?filter=${filter}`);
    }
  }

  getCategory(idCategory: number): Observable<IPoiCategory> {
    return this.httpClient.get<IPoiCategory>(this.url + 'detailcategory' + `?categoryId=${idCategory}`);
  }

  categoryUpsert(category: any) {
    return this.httpClient.post(this.url + 'upsertcategory', category);
  }

  deleteCategory(idCategory: number) {
    return this.httpClient.delete(this.url + 'deletecategory' + `?categoryId=${idCategory}`);
  }

  categoryAutoComplete(searchValue: string): Observable<any> {
    return this.httpClient.get<any>(this.url + 'autocompletecategories' + `?searchvalue=${searchValue}`);
  }
}
