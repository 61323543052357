import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUser } from '../../interface/user';


@Injectable({
  providedIn: 'root'
})
export class AccountService {

  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootAccount;
  }


  forgotPassword(email: string) {
    const body = {
      email
    };
    return this.httpClient.post(this.url + 'forgot', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }

  accountReset(forgotRequest: any) {
    return this.httpClient.post(this.url + 'reset', forgotRequest, { responseType: 'text' });
  }

  accountUpdate(profile: any) {
    return this.httpClient.post(this.url + 'update', profile);
  }

  accountChangePassword(profilePassword: any) {
    return this.httpClient.post(this.url + 'changepassword', profilePassword);
  }

  accountUsers(role: string, pageNumber: number, pageSize: number): Observable<any> {
    const parameters: string = !role ? '?pageNumber=' + pageNumber + '&pageSize=' + pageSize : '?Role=' + role + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize;
    return this.httpClient.get<any>(this.url + 'users' + parameters);
  }

  accountUser(Id: string): Observable<IUser> {
    const parameters = '?Id=' + Id;
    return this.httpClient.get<IUser>(this.url + 'read-user' + parameters);
  }

  accountUpsert(user: any) {
    return this.httpClient.post(this.url + 'upsertuser', user);
  }

  uploadProfileImage(file: any, userID: string) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post(`https://api.greenbike.ruletech.it/account/profileimg/upload?userId=${userID}`, formData, { responseType: 'text' });
  }

  accountChangeRole(roleObj: any) {
    return this.httpClient.post(this.url + 'changerole', roleObj);
  }

  accountDelete(Id: string) {
    const parameters = '?Id=' + Id;
    return this.httpClient.delete(this.url + 'delete-user' + parameters);
  }

  accountNoifications(pageNumber: number, pageSize: number) {
    const parameters: string = '?pageNumber=' + pageNumber + '&pageSize=' + pageSize;
    return this.httpClient.get<any>(this.url + 'notifications' + parameters);
  }

  getCitiesList() {
    return this.httpClient.get(`${this.url}listcities`);
  }
}
