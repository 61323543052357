import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { AccountService } from '../../../services/account/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarInfoComponent } from '../snackBar/snack-bar-info/snack-bar-info.component';
import { IUser } from '../../../interface/user';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  forgotForm: FormGroup;
  forgot = false;
  user: IUser;

  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private accountService: AccountService,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.checkLoginForm();
  }

  // Check ReactiveForm login.
  checkLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  // Check ReactiveForm forgot password.
  checkForgotForm() {
    this.forgotForm = this.formBuilder.group({
      emailAccount: [null, Validators.required]
    });
  }

  // Call API Auth/Token.
  onSubmit() {
    const loginDate = new Date();

    if (this.loginForm.invalid) {
      return;
    }

    const email: string = this.loginForm.value.email;
    const password: string = this.loginForm.value.password;

    this.isLoading = true;

    this.authService.signIn(email, password).subscribe((res: any) => {
      const currentDate = new Date().setSeconds(res.expiresIn);
      const expires = new Date(currentDate).toString();
      sessionStorage.setItem('accountToken', res.token);
      sessionStorage.setItem('refreshToken', res.refreshToken);
      sessionStorage.setItem('loginDate', expires);
      this.getProfile();
    }, error => {
        this.isLoading = false;
    });
  }

  // Call API Auth/getuser.
  getProfile() {
    this.authService.getUser().subscribe(user => {
      this.checkAccountRole(user.roles);
      sessionStorage.setItem('user', JSON.stringify(user));
    }, error => {
    });
  }

  // Check account role to navigate module correct.
  checkAccountRole(roles: Array<string>) {
    roles.forEach((role: string) => {
      switch (role) {
        case 'Administrator':
          this.router.navigate(['admin']);
          this.isLoading = false;
          break;

        case 'RentalStationUser':
          this.router.navigate(['rentalStation']);
          this.isLoading = false;
          break;

        case 'MarketingUser':
          this.router.navigate(['marketingUser']);
          this.isLoading = false;
          break;

        default:
          break;
      }
    });
  }

  changeForm() {
    this.forgot = true;
    this.checkForgotForm();
  }

  goToSignIn() {
    this.forgot = false;
  }

  sendEmail() {
    if (this.forgotForm.invalid) {
      return;
    }

    const email = this.forgotForm.value.emailAccount;

    this.accountService.forgotPassword(email).subscribe(res => {
      this.matSnackBar.openFromComponent(SnackBarInfoComponent, {
        data: 'We have sent a link to your email',
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
    }, error => {});
  }
}
