import { HttpClient, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarErrorComponent } from '../components/core/snackBar/snack-bar-error/snack-bar-error.component';
import { IErrorResponse } from '../interface/error-response';
import {environment} from '../../environments/environment';
import { LoadingService } from '../services/loading/loading.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  url: string;

  constructor(
    private router: Router,
    private matSnackBar: MatSnackBar,
    private loadingService: LoadingService
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootAuth;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<string>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const splitUrl = this.router.url.split('/');
        const homeUrl = `/${splitUrl[1]}`;
        const errorResponse: IErrorResponse = error.error;
        if (
          (homeUrl.includes('welcome') && error.status === 400 || homeUrl.includes('welcome') && error.status === 404) ||
          homeUrl.includes('welcome') && error.status === 422
        ) {
          this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
            data: errorResponse.uiMessage,
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          });
        }

        if ((!req.url.includes('welcome') && error.status === 400) || (!req.url.includes('welcome') && error.status === 409)) {
          this.loadingService.sendLoagingStatus(false);
          this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
            data: errorResponse.uiMessage,
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          });
        }

        if (error.status === 500) {
          this.loadingService.sendLoagingStatus(false);
          this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
            data: errorResponse.uiMessage,
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          });
        }

        return throwError(error);
      })
    );
  }
}
