import { Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-join-promotion-dialog',
  templateUrl: './join-promotion-dialog.component.html',
  styleUrls: ['./join-promotion-dialog.component.scss']
})
export class JoinPromotionDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

}
