import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PoiService } from '../../../services/POI/poi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { TranslateService } from '@ngx-translate/core';
import { IFacility } from '../../../interface/facility';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../services/loading/loading.service';

@Component({
  selector: 'app-delete-facility-dialog',
  templateUrl: './delete-facility-dialog.component.html',
  styleUrls: ['./delete-facility-dialog.component.scss']
})
export class DeleteFacilityDialogComponent implements OnInit, OnDestroy {

  facility: IFacility;
  currentLanguage: string;
  isLoading: boolean;
  isLoadingSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<DeleteFacilityDialogComponent>,
    private POIService: PoiService,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.facility = data;
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.currentLanguage = this.translateService.currentLang;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  deleteFacility() {
    this.isLoading = true;
    this.POIService.deleteFacility(this.facility.id).subscribe(res => {
      this.isLoading = false;
      this.translateService.get('FacilityDelete_200').subscribe(res =>
        this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
          data: res.title,
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      );
      this.closeDialog();
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
