import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { RentalStationService } from '../../../services/rental-station/rental-station.service';
import { PoiService } from '../../../services/POI/poi.service';
import { TranslateService } from '@ngx-translate/core';
import { IRent } from '../../../interface/rent';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../services/loading/loading.service';
import { Router } from '@angular/router';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  }
}

@Component({
  selector: 'app-edit-rent-dialog',
  templateUrl: './edit-rent-dialog.component.html',
  styleUrls: ['./edit-rent-dialog.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class EditRentDialogComponent implements OnInit, OnDestroy {
  root: string;
  upsertRentForm: FormGroup;
  idRent: number;
  minDateEndExpected: Date;
  minDateEndCarried: Date;
  isLoading: boolean;
  isLoadingSubscription: Subscription;
  currentLanguage: string;

  stationFacilities: Array<any> = [];
  rent: IRent;

  constructor(
    private dialogRef: MatDialogRef<EditRentDialogComponent>,
    private formBuilder: FormBuilder,
    private router: Router,
    private poiService: PoiService,
    private rentalStationService: RentalStationService,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
    this.idRent = data;
    this.root = this.router.url;
  }


  ngOnInit() {
    this.getDetail();
    this.getFacilities();
  }

  getDetail() {
    this.rentalStationService.getRent(this.idRent).subscribe(rent => {
      this.rent = rent;
      this.checkupsertForm(rent)
    });
  }

  getFacilities() {
    this.poiService.getFacilities('Rental', 0, 0, 0,false).subscribe(res => {
      res.result.forEach(rental => {
        let langInfos: Array<any> = [];
        if (this.currentLanguage === 'gb') {
          langInfos = rental.langInfos.filter(info => info.languageCode == 'en-GB');
          if (!langInfos.length) {
            langInfos = rental.langInfos.filter(info => info.languageCode == 'it-IT');
          }
          this.stationFacilities.push({ id: rental.id, label: langInfos[0].name })
        } else {
          langInfos = rental.langInfos.filter(info => info.languageCode == 'it-IT');
          if (!langInfos.length) {
            langInfos = rental.langInfos.filter(info => info.languageCode == 'en-GB');
          }
          this.stationFacilities.push({ id: rental.id, label: langInfos[0].name })
        }
      });
    });
  }

  checkupsertForm(rent: IRent) {
    if (rent) {
      this.upsertRentForm = this.formBuilder.group({
        code: [rent.code, Validators.required],
        station: [rent.rentalStation, Validators.required],
        muscleBike: [rent.muscleBike, Validators.required],
        bike: [rent.ebike, Validators.required],
        startDate: [rent.startDate, Validators.required],
        endDate: [rent.endDate],
        checkIn: [rent.checkIn],
        checkout: [rent.checkOut]
      });
    }
    this.minDateEndExpected = new Date(this.upsertRentForm.get('startDate').value);
    this.minDateEndCarried = new Date(this.upsertRentForm.get('checkIn').value);
  }

  changeDate(value: string, name: string) {
    name === 'startExpected'
      ? this.minDateEndExpected = new Date(this.upsertRentForm.get('startDate').value)
      : this.minDateEndCarried = new Date(this.upsertRentForm.get('checkIn').value)
  }

  onSubmit() {

    if (this.upsertRentForm.invalid)
      return;

    const rent: IRent = {
      id: this.rent.id,
      code: this.upsertRentForm.value.code,
      rentalStation: this.upsertRentForm.value.station,
      muscleBike: this.upsertRentForm.value.muscleBike,
      ebike: this.upsertRentForm.value.bike,
      startDate: this.upsertRentForm.value.startDate,
      endDate: this.upsertRentForm.value.endDate,
      checkIn: this.upsertRentForm.value.checkIn,
      checkOut: this.upsertRentForm.value.checkout,
    };
    this.upsertRent(rent);
  }

  upsertRent(rent: IRent) {
    this.isLoading = true;
    this.rentalStationService.rentUpsert(rent).subscribe(res => {
      this.isLoading = false;

      if (!this.root.includes('bookings')) {
        this.translateService.get('RentalEdit_200').subscribe(res =>
          this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
            data: res.title,
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: 'right'
          }));
      } else {
        this.translateService.get('BookingEdit_200').subscribe(res =>
          this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
            data: res.title,
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: 'right'
          }));
      }
      this.closeDialog();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
