import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {RentalStationService} from '../../../services/rental-station/rental-station.service';
import {LoadingService} from '../../../services/loading/loading.service';

@Component({
  selector: 'app-upload-order',
  templateUrl: './upload-order.component.html',
  styleUrls: ['./upload-order.component.scss']
})
export class UploadOrderComponent implements OnInit {
  file: File;
  stationFacilities: Array<any> = [];
  uploadForm: FormGroup;
  isLoading: boolean;
  isLoadingSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<UploadOrderComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private formBuilder: FormBuilder,
    private rentalStationService: RentalStationService,
    private loadingService: LoadingService
  ) {
    this.file = data.file;
    this.stationFacilities = data.stations.filter(station => station.id !== 0);
    console.log(this.file);
    console.log(this.stationFacilities);
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      station: [null, Validators.required]
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  upload() {
    const formData: FormData = new FormData();
    formData.append('csvFile', this.file, this.file.name);

    this.isLoading = true;
    this.rentalStationService.uploadOrderCsv(formData, this.uploadForm.value.station).subscribe(res => {
      this.isLoading = false;
      console.log(res);
      this.closeDialog();
    });
  }

}
