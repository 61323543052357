import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../services/account/account.service';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { AuthService } from '../../services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { IUser } from '../../interface/user';
import { switchMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { PoiService } from '../../services/POI/poi.service';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../services/loading/loading.service';
import { SpaceValidation } from '../../validators/space-validator';

@Component({
  selector: 'app-edit-password-dialog',
  templateUrl: './edit-password-dialog.component.html',
  styleUrls: ['./edit-password-dialog.component.scss']
})
export class EditPasswordDialogComponent implements OnInit, OnDestroy {

  editProfileForm: FormGroup;
  submitted = false;
  userStorage = sessionStorage.getItem('user');
  localUser: IUser = JSON.parse(this.userStorage);
  isLoading: boolean;
  isLoadingSubscription: Subscription;
  user: IUser;
  currentImage: any;
  image: any;
  file: File;


  constructor(
    private dialogRef: MatDialogRef<EditPasswordDialogComponent>,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private poiService: PoiService,
    private matSnackBar: MatSnackBar,
    private accountService: AccountService,
    private authService: AuthService,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private spaceValidation: SpaceValidation
  ) {
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.getProfile();
  }

  getProfile() {
    this.authService.getUser().subscribe(user => {
      this.user = user;
      this.checkEditForm(user)
    });
  }

  checkEditForm(user: IUser) {
    if (user) {
      this.editProfileForm = this.formBuilder.group({
        firstName: [user.firstName],
        lastName: [user.lastName],
        email: [user.email, [Validators.required, Validators.email]],
        userName: [user.userName, [Validators.required, this.spaceValidation.checkSpaceValidation]],
        phoneNumber: [user.phone, [Validators.pattern('^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\\\s\\\\./0-9]*$')]],
        birthDate: [null],
        profileImage: [null]
      });
      if (user.profileImage) {
        this.getImageProfile();
      }
    }
  }

  getImageProfile() {
    this.poiService.downloadMediaFile(Number(this.user.profileImage)).subscribe(res => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.currentImage = reader.result;
      }, false);

      if (res) {
        reader.readAsDataURL(res);
      }
    });
  }

  fileChangeEvent(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.currentImage = null;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => { this.image = event.target.result; }
      this.file = event.target.files[0];
      this.accountService.uploadProfileImage(this.file, this.user.id).subscribe(res => res);
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.editProfileForm.invalid)
      return;

    const profile = {
      id: this.user.id,
      firstName: this.editProfileForm.value.firstName,
      lastName: this.editProfileForm.value.lastName,
      email: this.editProfileForm.value.email,
      userName: this.editProfileForm.value.userName,
      phoneNumber: this.editProfileForm.value.phoneNumber,
      birthDate: this.user.birthDate,
      profileImage: this.user.profileImage
    }

    this.localUser.firstName = profile.firstName;
    this.localUser.lastName = profile.lastName;
    this.localUser.email = profile.email;
    this.localUser.userName = profile.userName;
    this.localUser.phoneNumber = profile.phoneNumber;
    this.localUser.birthDate = profile.birthDate;
    this.localUser.profileImage = profile.profileImage;

    sessionStorage.setItem('user', JSON.stringify(this.localUser));

    this.profileUpdate(profile);
  }

  profileUpdate(profile: any) {
    this.isLoading = true;

    if (this.file) {
      this.accountService.accountUpdate(profile).pipe(
        switchMap((res: any) => this.accountService.uploadProfileImage(this.file, profile.id))
      ).subscribe(res => {
        this.isLoading = false;
        this.translateService.get('ProfileEdit_200').subscribe(res =>
          this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
            data: res.title,
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: 'right'
          }));
        this.closeDialog();
      });

    } else {
      this.accountService.accountUpdate(profile).subscribe(res => {
        this.isLoading = false;
        this.translateService.get('ProfileEdit_200').subscribe(res =>
          this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
            data: res.title,
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: 'right'
          }));
        this.closeDialog();
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
