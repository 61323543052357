import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { EditPasswordDialogComponent } from '../../../dialog/edit-password-dialog/edit-password-dialog.component';
import { ProfileDialogComponent } from '../../../dialog/profile-dialog/profile-dialog.component';
import { IUser } from '../../../interface/user';
import { ChangePasswordDialogComponent } from '../../../dialog/change-password-dialog/change-password-dialog.component';
import { environment } from '../../../../environments/environment';
import { AccountService } from '../../../services/account/account.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  userStorage: string = sessionStorage.getItem('user');
  user: IUser = JSON.parse(this.userStorage);
  role: string;
  env = environment;
  notifications: Array<any> = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private accountService: AccountService,
    private router: Router,
    private dialog: MatDialog
  ) {
    translate.addLangs(['gb', 'it']);
    translate.setDefaultLang('gb');

    let browserLang = translate.getBrowserLang();
    let currentLanguage = sessionStorage.getItem('currentLanguage')


    if (currentLanguage)
      translate.use(currentLanguage);
    else
      translate.use(browserLang.match(/gb|it/) ? browserLang : 'gb');
  }


  ngOnInit() {
    this.checkRoleAccount();
    this.getNotificationList();
  }

  getNotificationList() {
    this.accountService.accountNoifications(0, 50).subscribe(res => {
      this.notifications = res.result;
    });
  }

  //Check Account role to show sidenav corrected.
  checkRoleAccount() {
    this.user.roles.forEach((role: string) => {
      switch (role) {
        case 'Administrator':
          this.role = 'Administrator';
          break;

        case 'RentalStationUser':
          this.role = 'RentalStationUser';
          break;

        case 'MarketingUser':
          this.role = 'MarketingUser';
          break;
      }
    });
  }

  //Method that change language.
  switchLanguage(language: string) {
    this.translate.use(language);
    sessionStorage.setItem('currentLanguage', language);
  }

  //Methot that signOut and deleted cookie from browser.
  signOut() {
    sessionStorage.removeItem('accountToken');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('loginDate');
    sessionStorage.removeItem('routeListLength');
    this.router.navigate(['welcome']);
  }

  //Open Edit Profile Dialog.
  openEditPasswordDialog() {
    const dialogRef = this.dialog.open(EditPasswordDialogComponent, {
      width: '400px',
      disableClose: true,
      autoFocus: false
    });
  }

  //Open details profile dialog.
  openProfileDialog() {
    const dialogRef = this.dialog.open(ProfileDialogComponent, {
      width: '400px',
      disableClose: true,
      autoFocus: false
    });
  }

  openChagePasswordDialog() {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: '400px',
      disableClose: true,
      autoFocus: false
    });
  }
}
