import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PoiService } from '../../../services/POI/poi.service';
import { IFacility } from '../../../interface/facility';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-detail-facility-dialog',
  templateUrl: './detail-facility-dialog.component.html',
  styleUrls: ['./detail-facility-dialog.component.scss']
})
export class DetailFacilityDialogComponent implements OnInit {

  @ViewChild('mapElement', { static: true }) mapElement: ElementRef;
  facility: IFacility;
  facility$: Observable<IFacility>;
  currentLanguage: string;
  currentImage: any;

  constructor(
    private sanitizer: DomSanitizer,
    private POIService: PoiService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<DetailFacilityDialogComponent>, @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.facility = data;
  }

  isLoading: boolean;

  ngOnInit() {
    this.currentLanguage = this.translateService.currentLang;
    this.getCurrentFacility();
    this.getPoisheet();
  }

  closeDialog() {
    this.dialogRef.close();
  }


  getCurrentFacility() {
    this.facility$ = this.POIService.getFacilityDetails(this.facility.id);
  }

  getPoisheet() {
    this.POIService.getpoisheet(this.facility.id).pipe(
      switchMap((res: any) => res.sheet ? this.POIService.downloadMediaFile(res.sheet.attachments[0].id) : of(null))
    ).subscribe(res => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.currentImage = reader.result;
      }, false);

      if (res) {
        reader.readAsDataURL(res);
      }
    });
  }
}
