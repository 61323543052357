import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { PoiService } from '../../../../services/POI/poi.service';
import { SnackBarSuccessComponent } from '../../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../../services/loading/loading.service';

@Component({
  selector: 'app-delete-poi-dialog',
  templateUrl: './delete-poi-dialog.component.html',
  styleUrls: ['./delete-poi-dialog.component.scss']
})
export class DeletePoiDialogComponent implements OnInit, OnDestroy{
  poi: any;
  isLoading: boolean;
  isLoadingSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<DeletePoiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private matSnackBar: MatSnackBar,
    private poiService: PoiService,
    private loadingService: LoadingService
  ) {
    this.poi = data;
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  delete() {
    this.isLoading = true;
    this.poiService.deletePoi(this.poi.id).subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Punto di interesse eliminato con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right'
      });
      this.closeDialog();
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
