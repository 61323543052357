import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '../../services/account/account.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IUser } from '../../interface/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../components/core/snackBar/snack-bar-success/snack-bar-success.component';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  localUser: string = sessionStorage.getItem('user');
  user: IUser = JSON.parse(this.localUser);
  editForm: FormGroup;

  constructor(
    private accountService: AccountService,
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private formbuilder: FormBuilder,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.checkEditForm();
  }

  checkEditForm() {
    this.editForm = this.formbuilder.group({
      oldPassword: [null, Validators.required],
      newPassword: [null, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\\s])[A-Za-z\d^a-zA-Z0-9\\s].{5,}')]],
      confermaPassword: [null, Validators.required]
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.editForm.invalid) {
      return;
    }

    if (this.editForm.value.newPassword !== this.editForm.value.confermaPassword) {
      this.editForm.get('confermaPassword').setErrors([{ 'confirmPasswordError': true }]);
      return;
    }


    const profilePassword = {
      id: this.user.id,
      oldPassword: this.editForm.value.oldPassword,
      newPassword: this.editForm.value.newPassword,
      confermaPassword: this.editForm.value.confermaPassword
    }

    this.updatePassword(profilePassword);
  }

  updatePassword(profilePassword: any) {

    this.accountService.accountChangePassword(profilePassword).subscribe(res => {
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Password Modificata con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right'
      });
      this.closeDialog();
    });

  }

}
