import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidation } from '../../../validators/password-validator';
import { AccountService } from '../../../services/account/account.service';
import { AlertService } from 'ngx-alerts';
import { tap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  token: string;
  IdUser: string;
  resetPasswordForm: FormGroup
  submitted = false;

  constructor(
    private router: Router,
    private enableRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.getParamsFromLink();
    this.checkResetPasswordForm();
  }

  //Method that get query params from link.
  getParamsFromLink() {
    this.enableRoute.queryParams.subscribe(param => {
      this.token = param._token;
      this.IdUser = param._userId;
    });
  }

  //Check ReactiveForm reset password.
  checkResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required]
    },
      { validator: PasswordValidation.matchPassword });
  }

  //Call API Account/reset
  resetPassword() {
    this.submitted = true;

    if (this.resetPasswordForm.invalid)
      return;

    let password = this.resetPasswordForm.value.password;
    let confirmPassword = this.resetPasswordForm.value.confirmPassword;

    const forgotReq  = {
      idUser: '',
      password: password,
      confirmPassword: confirmPassword,
      token: ''
    }

    const resetPasswordAccount$ = this.enableRoute.queryParams.pipe(
      tap(res => { forgotReq.idUser = res.userId; forgotReq.token = decodeURIComponent(res.token.replace(/ /gi, '+')); }),
      switchMap(param =>
        this.accountService.accountReset(forgotReq)
      )
    );

    resetPasswordAccount$.subscribe( res => {
      this.router.navigate(['welcome'])
    }, error => {
        if (error.status === 422) {
          this.alertService.danger(JSON.parse(error.error).uiMessage);
        }
       
    });
  }

}
