import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromoService } from '../../../services/promo/promo.service';
import { Observable, of } from 'rxjs';
import { IPromo } from '../../../interface/promo';
import { switchMap } from 'rxjs/operators';
import { PoiService } from '../../../services/POI/poi.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-detail-promotion-dialog',
  templateUrl: './detail-promotion-dialog.component.html',
  styleUrls: ['./detail-promotion-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DetailPromotionDialogComponent implements OnInit {

  idPromo: number;
  promo$: Observable<IPromo>;
  isLoading: boolean;
  currentImage: any;

  constructor(
    private promoService: PromoService,
    private sanitizer: DomSanitizer,
    private poiService: PoiService,
    private dialogRef: MatDialogRef<DetailPromotionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.idPromo = data;
  }

  ngOnInit() {
    this.getCurrentPromotion();
    this.getPromoAttachments();
  }

  getCurrentPromotion() {
    this.isLoading = true;
    this.promo$ = this.promoService.promoDetail(this.idPromo);
  }

  getPromoAttachments() {
    this.promoService.promoDetail(this.idPromo).pipe(
      switchMap(res => res.attachments.length ? this.poiService.downloadMediaFile(res.attachments[0].id) : of(null))
    ).subscribe(res => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.currentImage = reader.result;
      }, false);

      if (res) {
        reader.readAsDataURL(res);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
