import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as mapboxgl from 'mapbox-gl';
import { BikeGeolocation } from '../../../model/bike-geolocation';
import { PoiService } from '../../../services/POI/poi.service';
import { IPoiMap } from '../../../interface/poi-map';
import { IFacility } from '../../../interface/facility';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { EditPoiDialogComponent } from '../../../dialog/admin/route-dialog/edit-poi-dialog/edit-poi-dialog.component';
import { MatDialog } from '@angular/material';
import { DeletePoiDialogComponent } from '../../../dialog/admin/route-dialog/delete-poi-dialog/delete-poi-dialog.component';
import { Router } from '@angular/router';
import { AddPoiDialogComponent } from '../../../dialog/admin/route-dialog/add-poi-dialog/add-poi-dialog.component';
import { DetailFacilityDialogComponent } from '../../../dialog/admin/detail-facility-dialog/detail-facility-dialog.component';



@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  url: string;
  map: mapboxgl.Map;

  bikeGeoLocation: BikeGeolocation;
  geoJsonArray: Array<any> = [];

  bikesCount = 0;
  poiCount = 0;
  rentalCount = 0;
  markerCount = 0;
  poiDeleteCount = 0;

  poiSelected: any;
  facilitySelected: any;

  poiname: string;
  poiType: string;

  poiTypes: Array<any> = [
    { label: 'Tutti', value: 'All' },
    {label: 'Attività commerciale', value: 'Market'},
    { label: 'Stazione di noleggio', value: 'Rental' },
    { label: 'Turistico', value: 'Tourist' },
    { label: 'Tecnico', value: 'Technical' },
  ];

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private POIService: PoiService,
    private renderer: Renderer2
  ) { }


  isLoading: boolean;

  ngOnInit() {
    this.url = this.router.url;
    this.getBikes();
  }

  changeName(name: string) {
    if (name.length >= 3) {
      this.poiname = name;
      this.getBikes();
    }

    if (!name.length) {
      this.poiname = null;
      this.getBikes();
    }
  }

  changeType(type: string) {
    if (type !== 'All') {
      this.poiType = type;
      this.getBikes();
    } else {
      this.poiType = null;
      this.getBikes();
    }
  }

  // Call API Traking/Atlas
  getBikes() {
    this.isLoading = true;
    this.geoJsonArray = [];
    this.POIService.getAtlas(this.poiType, this.poiname).subscribe((res: any) => {
      this.isLoading = false;
      this.createMarkerBikes(res.park.bikes);
      this.createMarkerRentalStations(res.rentalStations);
      this.createPoiMarker(res.poIs);
      this.populateMap(this.geoJsonArray);
    }, error => {
      this.isLoading = false;
    });
  }

  // Method that create geojson bikes array
  createMarkerBikes(bikes: Array<any>) {
    const bikeList: Array<any> = bikes.filter(bike => bike);
    this.bikesCount = bikeList.length;

    bikeList.forEach(element => {
      this.bikeGeoLocation = new BikeGeolocation;
      this.bikeGeoLocation.deviceIMEI = element.deviceIMEI;
      this.bikeGeoLocation.latitude = element.latitude;
      this.bikeGeoLocation.longitude = element.longitude;
      this.bikeGeoLocation.timestamp = new Date(element.timestamp)

      const dateMarker = this.datePipe.transform(this.bikeGeoLocation.timestamp, 'dd/MM/yyyy HH:mm');

      const geojson = {
        type: 'Bike',
        geometry: {
          type: 'Point',
          coordinates: { lat: element.latitude, lng: element.longitude }
        },
        properties: {
          title: this.bikeGeoLocation.deviceIMEI,
          description: dateMarker,
          type: 'Bike'
        }
      };

      this.geoJsonArray.push(geojson);
    })
  }

  // Method that create geojson RentalStations array
  createMarkerRentalStations(rentalStations: Array<IFacility>) {
    const rentalStationList = rentalStations.filter(rental => rental);
    this.rentalCount = rentalStationList.length;


    rentalStationList.forEach(element => {
      const langInfos: any = this.getCurrentLanguageInformation(element.langInfos);
      const geojson = {
        type: 'RentalStation',
        geometry: {
          type: 'Point',
          coordinates: { lat: element.latitude, lng: element.longitude }
        },
        properties: {
          'id': element.id,
          'title': langInfos.name,
          'description': langInfos.address,
          'type': 'Stazione di noleggio'
        }
      };

      this.geoJsonArray.push(geojson);
    });
  }

  createPoiMarker(pois: Array<IPoiMap>) {
    const marketList = pois.filter(poi => poi.type === 'Market');
    this.markerCount = marketList.length;

    const deletePoiList = pois.filter(poi => poi.type !== 'Market' &&  poi.deleted === true);
    this.poiDeleteCount = deletePoiList.length;


    const poisList = pois.filter(poi => poi.type !== 'Market' && !poi.deleted );
    this.poiCount = poisList.length;

    deletePoiList.forEach(element => {
      const langInfos: any = this.getCurrentLanguageInformation(element.langInfos);
      const geojson = {
        type: 'DeletePois',
        geometry: {
          type: 'Point',
          coordinates: { lat: element.latitude, lng: element.longitude }
        },
        properties: {
          id: element.id,
          title: langInfos.name,
          description: langInfos.address,
          type: element.type === 'Tourist' ? 'Punto turistico' : 'Punto tecnico'
        }
      };

      this.geoJsonArray.push(geojson);
    });

    poisList.forEach(element => {
      const langInfos: any = this.getCurrentLanguageInformation(element.langInfos);
      const geojson = {
        type: 'Pois',
        geometry: {
          type: 'Point',
          coordinates: { lat: element.latitude, lng: element.longitude }
        },
        properties: {
          id: element.id,
          title: langInfos.name,
          description: langInfos.address,
          type: element.type === 'Tourist' ? 'Punto turistico' : 'Punto tecnico'
        }};

      this.geoJsonArray.push(geojson);
    });

    marketList.forEach(element => {
      const langInfos: any = this.getCurrentLanguageInformation(element.langInfos);
      const geojson = {
        type: 'Market',
        geometry: {
          type: 'Point',
          coordinates: { lat: element.latitude, lng: element.longitude }
        },
        properties: {
          'id': element.id,
          'title': langInfos.name,
          'description': langInfos.address,
          'type': 'Attività commerciale'
        }
      };

      this.geoJsonArray.push(geojson);
    })
  }

  getCurrentLanguageInformation(currentLangInfos: Array<any>): any {
    let langObj: any;
    let langInfos: Array<any> = [];

    if (this.translateService.currentLang === 'gb') {
      langInfos = currentLangInfos.filter(info => info.languageCode == 'en-GB');
      if (!langInfos.length) {
        langInfos = currentLangInfos.filter(info => info.languageCode == 'it-IT');
      }
      langObj = {
        name: langInfos.length  ? langInfos[0].name : 'Non disponibile',
        address: langInfos.length  ? langInfos[0].address: 'Non disponibile'
      };
    } else {
      langInfos = currentLangInfos.filter(info => info.languageCode == 'it-IT');

      if (!langInfos.length) {
        langInfos = currentLangInfos.filter(info => info.languageCode == 'en-GB');
      }
      langObj = {
        name: langInfos.length ? langInfos[0].name : 'Non disponibile',
        address: langInfos.length ? langInfos[0].address : 'Non disponibile'
      };
    }
    return langObj;
  }

  // Method create map and populate with marker
  populateMap(geoJsonArray: Array<any>) {
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.map = new mapboxgl.Map({
          container: 'mapID',
          style: 'mapbox://styles/ruletech/ckf3os5jp0vql19pf36ujasna',
          zoom: 13,
          center: [position.coords.longitude, position.coords.latitude]
        });

        geoJsonArray.forEach((element: any) => {
          const customMarker = this.renderer.createElement('div');

          if (element.type == 'Bike') {
            this.renderer.addClass(customMarker, 'bikesMarker');
          }


          if (element.type == 'RentalStation') {
            this.renderer.addClass(customMarker, 'rentalStationMarker');
          }


          if (element.type === 'Pois') {
            this.renderer.addClass(customMarker, 'poisMarker');
          }

          if (element.type === 'DeletePois') {
            this.renderer.addClass(customMarker, 'deleteMarker');
          }

          if (element.type === 'Market') {
            this.renderer.addClass(customMarker, 'marker-market');
          }

          const marker = new mapboxgl.Marker(customMarker);
          marker.setLngLat(element.geometry.coordinates);
          marker.setPopup(
            new mapboxgl.Popup({ offset: 25 }).setHTML(
              `<h3 style="overflow: hidden; text-overflow: ellipsis !important; white-space: nowrap !important;">${element.properties.title}</h3>
              <span style="overflow: hidden; text-overflow: ellipsis !important; white-space: nowrap !important;">${element.properties.description}</span>
              <p> ${element.properties.type}</p>`
            )
          );

          if (this.url.includes('atlas')) {
            marker.getElement().addEventListener('click', () => {
              if (element.type === 'Pois') {
                this.facilitySelected = null;
                this.poiSelected = {
                  id: element.properties.id,
                  name: element.properties.title,
                  address: element.properties.description,
                  type: element.properties.type
                };
              }
            });
          }

          marker.getElement().addEventListener('click', () => {
            if (element.type === 'Market' || element.type === 'RentalStation') {
              this.poiSelected = null;
              this.facilitySelected = {
                id: element.properties.id,
                name: element.properties.title,
                address: element.properties.description,
                type: element.properties.type
              };
            }
          });

          marker.addTo(this.map);
        });
        this.map.addControl(new mapboxgl.NavigationControl());
      });
    }
  }

  add() {
    const dialogRef = this.dialog.open(AddPoiDialogComponent, {
      width: '1200px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      this.getBikes();
      this.poiSelected = null;
    });
  }

  edit(IdPoi: any) {
    const dialogRef = this.dialog.open(EditPoiDialogComponent, {
      width: '1200px',
      data: IdPoi,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      this.getBikes();
      this.poiSelected = null;
    });
  }

  delete(poi: any) {
    const dialogRef = this.dialog.open(DeletePoiDialogComponent, {
      width: '600px',
      data: poi,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      this.getBikes();
      this.poiSelected = null;
    });
  }

  openDetailsFacilityDialog(facilitySelected: IFacility) {
    const dialogRef = this.dialog.open(DetailFacilityDialogComponent, {
      width: '600px',
      data: facilitySelected,
      disableClose: true,
      autoFocus: false
    });
  }

  // Method that refresh map.
  refreshMap() {
    this.geoJsonArray = [];
    this.getBikes();
  }









}
