import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../../app/module/angular-material/angular-material.module';
import { RouterModule } from '@angular/router';

import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CKEditorModule } from 'ckeditor4-angular';

import { NavbarComponent } from '../../components/core/navbar/navbar.component';
import { FooterComponent } from '../../components/core/footer/footer.component';
import { MapComponent } from '../../components/shared/map/map.component';

import { SnackBarSuccessComponent } from '../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { SnackBarErrorComponent } from '../../components/core/snackBar/snack-bar-error/snack-bar-error.component';
import { SnackBarInfoComponent } from '../../components/core/snackBar/snack-bar-info/snack-bar-info.component';

import { SpinnerComponent } from '../../components/shared/spinner/spinner.component';



import { EditPasswordDialogComponent } from '../../dialog/edit-password-dialog/edit-password-dialog.component';
import { ProfileDialogComponent } from '../../dialog/profile-dialog/profile-dialog.component';
import { ChangePasswordDialogComponent } from '../../dialog/change-password-dialog/change-password-dialog.component';

import { AddFacilityDialogComponent } from '../../dialog/admin/add-facility-dialog/add-facility-dialog.component';
import { DetailFacilityDialogComponent } from '../../dialog/admin/detail-facility-dialog/detail-facility-dialog.component';
import { EditFacilityDialogComponent } from '../../dialog/admin/edit-facility-dialog/edit-facility-dialog.component';
import { DeleteFacilityDialogComponent } from '../../dialog/admin/delete-facility-dialog/delete-facility-dialog.component';

import { RentalStationComponent } from '../../components/admin/facility-management/rental-station/rental-station.component';

import { AddRentDialogComponent } from '../../dialog/rental-station/add-rent-dialog/add-rent-dialog.component';
import { DetailRentDialogComponent } from '../../dialog/rental-station/detail-rent-dialog/detail-rent-dialog.component';
import { DeleteRentDialogComponent } from '../../dialog/rental-station/delete-rent-dialog/delete-rent-dialog.component';
import { EditRentDialogComponent } from '../../dialog/rental-station/edit-rent-dialog/edit-rent-dialog.component';
import {UploadOrderComponent} from '../../dialog/rental-station/upload-order/upload-order.component';

import { PromotionsComponent } from '../../components/marketing/promotions/promotions.component';
import { AddPromotionDialogComponent } from '../../dialog/marketing/add-promotion-dialog/add-promotion-dialog.component';
import { DetailPromotionDialogComponent } from '../../dialog/marketing/detail-promotion-dialog/detail-promotion-dialog.component';
import { EditPromotionDialogComponent } from '../../dialog/marketing/edit-promotion-dialog/edit-promotion-dialog.component';
import { JoinPromotionDialogComponent } from '../../dialog/marketing/join-promotion-dialog/join-promotion-dialog.component';

import { HttpTokenInterceptor } from '../../interceptor/http-token-interceptor';
import { HttpErrorInterceptor } from '../../interceptor/http-error-interceptor';



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    SnackBarSuccessComponent,
    SnackBarInfoComponent,
    SnackBarErrorComponent,
    NavbarComponent,
    FooterComponent,
    MapComponent,
    SpinnerComponent,
    EditPasswordDialogComponent,
    ProfileDialogComponent,
    ChangePasswordDialogComponent,
    AddFacilityDialogComponent,
    DetailFacilityDialogComponent,
    EditFacilityDialogComponent,
    DeleteFacilityDialogComponent,
    RentalStationComponent,
    AddRentDialogComponent,
    DetailRentDialogComponent,
    DeleteRentDialogComponent,
    EditRentDialogComponent,
    UploadOrderComponent,
    PromotionsComponent,
    AddPromotionDialogComponent,
    DetailPromotionDialogComponent,
    EditPromotionDialogComponent,
    JoinPromotionDialogComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule,
    CKEditorModule,
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    MapComponent,
    RentalStationComponent,
    EditPasswordDialogComponent,
    ProfileDialogComponent,
    CKEditorModule,
    SpinnerComponent
  ],
  entryComponents: [
    SnackBarSuccessComponent,
    SnackBarInfoComponent,
    SnackBarErrorComponent,

    EditPasswordDialogComponent,
    ProfileDialogComponent,
    ChangePasswordDialogComponent,

    AddFacilityDialogComponent,
    DetailFacilityDialogComponent,
    EditFacilityDialogComponent,
    DeleteFacilityDialogComponent,

    AddRentDialogComponent,
    DetailRentDialogComponent,
    DeleteRentDialogComponent,
    EditRentDialogComponent,
    UploadOrderComponent,

    AddPromotionDialogComponent,
    DetailPromotionDialogComponent,
    EditPromotionDialogComponent,
    JoinPromotionDialogComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
})
export class SharedComponentsModuleModule { }
