import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertModule } from 'ngx-alerts';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AngularMaterialModule } from '../app/module/angular-material/angular-material.module';
import { SharedComponentsModuleModule } from '../app/module/shared-components-module/shared-components-module.module';

import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/core/login/login.component';
import { ResetPasswordComponent } from './components/core/reset-password/reset-password.component';
import { SpaceValidation } from './validators/space-validator';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ResetPasswordComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        AlertModule.forRoot({ timeout: 3000, position: 'right' }),
        ReactiveFormsModule,
        HttpClientModule,
        AngularMaterialModule,
        AppRoutingModule,
        SharedComponentsModuleModule

  ],
  providers: [
    CookieService,
    DatePipe,
    SpaceValidation
  ],
    bootstrap: [AppComponent]
})
export class AppModule { }
