import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IRent } from '../../interface/rent';
import { IFacility } from '../../interface/facility';

@Injectable({
  providedIn: 'root'
})
export class RentalStationService {

  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootRentalStation;
  }

  getRentalStation(idRentalStation: string): Observable<IFacility> {
    const parameters: string = '?idRentalStation=' + idRentalStation;
    return this.httpClient.get<IFacility>(this.url + 'station-summary' + parameters);
  }

  rentUpsert(rent: IRent) {
    return this.httpClient.post(this.url + 'upsert', rent);
  }

  getRents(startdate: string, enddate: string, checkin: string, checkout: string, facilityId: number, pageNumber: number, pageSize: number): Observable<any> {
    let parameters: string = '';

    if (startdate) {
      parameters += !parameters.length ? '?_startdate=' + startdate : '&_startdate=' + startdate
    }
    if (enddate) {
      parameters += !parameters.length ? '?_enddate=' + enddate : '&_enddate=' + enddate
    }
    if (checkin) {
      parameters += !parameters.length ? '?_checkin=' + checkin : '&_checkin=' + checkin
    }
    if (checkout) {
      parameters += !parameters.length ? '?_checkout=' + checkout : '&_checkout=' + checkout
    }

    parameters += parameters.length ? '&facilityId=' + facilityId + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize : '?facilityId=' + facilityId +  '&pageNumber=' + pageNumber + '&pageSize=' + pageSize;

    return this.httpClient.get<any>(this.url + 'rents' + parameters)
  }

  getRentsWithFilter(filter: string, value: string, pageNumber: number, pageSize: number) {
    const parameters = `?${filter}=${value}` + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize;
    return this.httpClient.get<any>(this.url + 'rents' + parameters)
  }

  getRent(idRent: number): Observable<IRent> {
    const parameters: string = '?idRent=' + idRent;
    return this.httpClient.get<IRent>(this.url + 'rent' + parameters);
  }

  deleteRent(idRent: number) {
    const parameters: string = '?idRent=' + idRent;
    return this.httpClient.delete(this.url + 'delete' + parameters);
  }

  acceptRent(IdRent: number) {
    const body = { idRent: IdRent };
    return this.httpClient.post(this.url + 'accept', body, { responseType: 'text' });
  }

  uploadOrderCsv(formData: FormData, rentalStationId: number) {
    return this.httpClient.post(`${this.url}upload-order-csv?rentalStationId=${rentalStationId}`, formData);
  }
}
