import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IPromo } from '../../interface/promo';
import { IPromoRoute } from '../../interface/promo-route';

@Injectable({
  providedIn: 'root'
})
export class PromoService {

  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootPromo;
  }

  getPromos(pageNumber: number, pageSize: number, sort: number, order: boolean): Observable<any> {
    const parameters: string = '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&sort=' + sort + '&order=' + order;
    return this.httpClient.get<any>(this.url + 'promos' + parameters);
  }

  promoUpsert(promo: any) {
    return this.httpClient.post(this.url + '/upsert', promo);
  }

  promoDetail(IdPromo: number): Observable<IPromo> {
    const parameters = '?IdPromo=' + IdPromo;
    return this.httpClient.get<IPromo>(this.url + 'detail' + parameters);
  }

  getPromosRoute(pageNumber: number, pageSize: number): Observable<any> {
    const parameters: string = '?pageNumber=' + pageNumber + '&pageSize=' + pageSize;
    return this.httpClient.get<any>(this.url + '/listroutepromo' + parameters);
  }

  routePromoUpsert(promo: any) {
    return this.httpClient.post(this.url + '/upsertroutepromo', promo);
  }

  routePromoDetail(IdPromo: number): Observable<IPromoRoute> {
    const parameters = '?promoId=' + IdPromo;
    return this.httpClient.get<IPromoRoute>(this.url + 'detailroutepromo' + parameters);
  }

}
