import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {AuthService} from '../services/auth/auth.service';

@Injectable()
export class HttpTokenInterceptor implements  HttpInterceptor {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqUrl = req.url;
    const baseUrlAPI = `${environment.protocol}${environment.apiBaseUrl}`;

    if (!reqUrl.includes('uploadmedia') && !reqUrl.includes('api.mapbox.com') && !reqUrl.includes('upload') && !reqUrl.includes('upload-order-csv')) {
      req = req.clone({ headers: req.headers.set('Accept-Language', 'it-IT') });

      if (!req.headers.has('Content-Type')) {
        req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
      }
    }

    // tslint:disable-next-line:max-line-length
    if (reqUrl !== `${baseUrlAPI}/Auth/Token` && reqUrl !== `${baseUrlAPI}/Account/forgot` && reqUrl !== `${baseUrlAPI}/Account/reset` && !req.url.includes('api.mapbox.com')) {
      return next.handle(this.injectToken(req)).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 || error.status === 403) {
            return this.authService.injectRefreshToken().pipe(
              tap((res: any) => {
                const currentDate = new Date().setSeconds(res.expiresIn);
                const expires = new Date(currentDate).toString();
                sessionStorage.removeItem('accountToken');
                sessionStorage.setItem('accountToken', res.token);
                sessionStorage.removeItem('refreshToken');
                sessionStorage.setItem('refreshToken', res.refreshToken);
                sessionStorage.removeItem('loginDate');
                sessionStorage.setItem('loginDate', expires);
              }),
              switchMap(() => next.handle(this.injectToken(req)))
            );
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }

  injectToken(req: HttpRequest<any>) {
    const accessToken: string = sessionStorage.getItem('accountToken');
    if (accessToken) {
      return req.clone({
        headers: req.headers.set('Authorization', `Bearer ${accessToken}`)
      });
    }
  }
}
