import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IPromo } from '../../../interface/promo';
import { PromoService } from '../../../services/promo/promo.service';

@Component({
  selector: 'app-delete-promotion-dialog',
  templateUrl: './delete-promotion-dialog.component.html',
  styleUrls: ['./delete-promotion-dialog.component.scss']
})
export class DeletePromotionDialogComponent implements OnInit {
  isLoading: boolean;
  promo: IPromo;

  constructor(
    private dialogRef: MatDialogRef<DeletePromotionDialogComponent>,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    private promoService: PromoService,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.promo = data;
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
