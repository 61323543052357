import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { PoiService } from '../../../services/POI/poi.service';
import { PromoService } from '../../../services/promo/promo.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { SnackBarSuccessComponent } from '../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { IFacility } from '../../../interface/facility';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  }
}

@Component({
  selector: 'app-add-promotion-dialog',
  templateUrl: './add-promotion-dialog.component.html',
  styleUrls: ['./add-promotion-dialog.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class AddPromotionDialogComponent implements OnInit {

  languageForm: FormGroup;
  informationForm: FormGroup;
  facilitiesForm: FormGroup;
  currentDate: Date = new Date();
  minDate: Date = new Date(this.currentDate.getFullYear(), this.currentDate.getUTCMonth(), this.currentDate.getUTCDate());
  minDateEnd: Date;
  facilities: Array<any> = [];
  facilitiesSelect: Array<any> = [];

  image: any;
  file: File;
  fileName: string;
  attachments: Array<any> = [];

  constructor(
    private dialogRef: MatDialogRef<AddPromotionDialogComponent>,
    private formBuilder: FormBuilder,
    private POIService: PoiService,
    private promoService: PromoService,
    private translateService: TranslateService,
    private matSnackBar: MatSnackBar
  ) { }


  ngOnInit() {
    this.initForms();
    this.getFacilities();
  }

  fileChangeEvent(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => { this.image = event.target.result; };
      this.file = event.target.files[0];
      this.POIService.uploadMediaFile(this.file, 'Promo').subscribe(res => {
        this.attachments = [{
          id: 0,
          fileName: res,
          type: 'Image',
          langInfos: []
        }];
      });
    }
  }

  initForms() {
    this.languageForm = this.formBuilder.group({
      language: ['', Validators.required],
      infos: this.formBuilder.array([])
    });


    this.informationForm = this.formBuilder.group({
      start: [null, Validators.required],
      end: [null, Validators.required],
      leftTimeMin: [null, Validators.required],
      disabled: [false],
      url: [null, [Validators.pattern('^(ftp|http|https):\/\/[^ "]+$')]],
      radius: [0],
      showcaseEnd: [null],
    });

    this.facilitiesForm = this.formBuilder.group({
      facility: [null],
      infoFacilties: this.formBuilder.array([])
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // Form Language
  get infosForms() {
    return this.languageForm.get('infos') as FormArray;
  }

  onChangeLanguages(language: string) {
    this.languageForm.get('language').clearValidators();
    this.languageForm.get('language').updateValueAndValidity();
    this.languageForm.get('language').reset();
    const info = this.formBuilder.group({
      languageCode: [language],
      title: [null, Validators.required],
      description: [null, Validators.required],
      termsOfUse: [null, Validators.required],
    });

    if (this.infosForms.length > 0) {
      this.checkIfInfoExist(info);
    }
    else {
      this.infosForms.push(info);
    }
  }

  checkIfInfoExist(info: any) {
    let find: boolean;

    this.infosForms.controls.forEach((item) => {
      if (item.get('languageCode').value === info.get('languageCode').value) {
        find = true;
        return;
      }
    });

    if (!find) {
      this.infosForms.push(info);
    }
  }

  removeLanguage(index: number) {
    this.infosForms.removeAt(index);
    this.languageForm.get('language').reset();
  }

  getLanguageInfoValue() {
    if (!this.infosForms.controls.length) {
      this.languageForm.get('language').setValidators([Validators.required]);
      this.languageForm.get('language').updateValueAndValidity();
      return;
    }
  }
  // End Form Language

  // Form information
  getInformationValue() {
    if (this.informationForm.invalid) {
      return;
    }
  }
  // End Form information

  // Set value to form property
  changeDate(value: string) {
    this.minDateEnd = new Date(new Date(this.informationForm.get('start').value).setHours(0, 0, 0, 0));
  }

  // Facility associated
  getFacilities() {
    this.POIService.getFacilities('Market', 0, 500, 0, false).subscribe(res => {
      this.fillFacilities(res.result);
    }, error => {
    });
  }

  fillFacilities(facilities: Array<IFacility>) {
    facilities.forEach(facility => {
      this.facilities.push({ id: facility.id, name: facility.langInfos[0].name });
    });
  }

  get infoFacilities() {
    return this.facilitiesForm.get('infoFacilties') as FormArray;
  }

  selectFacility(facility: any) {
    const infoFacility = this.formBuilder.group({
      id: [facility.id],
      name: [facility.name],
      radius: [this.informationForm.get('radius').value]
    });

    if (this.infoFacilities.length > 0) {
      this.checkFacilityExist(infoFacility);
    } else {
      this.infoFacilities.push(infoFacility);
    }
    this.facilitiesForm.get('facility').setValue(null);
  }

  checkFacilityExist(currentFacility: any) {
    let find: boolean;

    this.infoFacilities.controls.forEach((facility) => {
      if (facility.get('id').value === currentFacility.get('id').value) {
        find = true;
        return;
      }
    });

    if (!find) {
      this.infoFacilities.push(currentFacility);
    }
  }

  removeFacility(index: number) {
    this.infoFacilities.removeAt(index);
  }

  getFacilitiesValue() {
    if (this.facilitiesForm.invalid) {
      return;
    }

    if (this.infoFacilities.length > 0) {
      this.infoFacilities.controls.forEach(item => {
        this.facilitiesSelect.push({ idStruttura: item.get('id').value, radiusStruttura: item.get('radius').value });
      });
    }
    this.upsertPromo();
  }
  // End facility associated

  upsertPromo() {
    const promo = {
      strutture: this.facilitiesSelect,
      langInfos: this.languageForm.value.infos,
      start: this.informationForm.value.start,
      end: this.informationForm.value.end,
      leftTimeMin: this.informationForm.value.leftTimeMin,
      id: 0,
      disabled: this.informationForm.value.disabled,
      url: this.informationForm.value.url,
      radius: this.informationForm.value.radius,
      showcaseEnd: this.informationForm.value.showcaseEnd,
      attachments: this.attachments
    };

    this.promoService.promoUpsert(promo).subscribe(res => {
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Promozione aggiunta con successo',
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.closeDialog();
    }, error => {});
  }

}
