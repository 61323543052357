export const environment = {
  production: false,

  clientId: 'b0ff58f48f377ef69c5abfea745654372ec32013.greenbike.backoffice',

  clientSecret: 'e8f0c84c-cae0-3750-d890-c79666c6a36f',

  protocol: 'https://',

  apiBaseUrl:'api.greenbike.ruletech.it',

  rootAuth: '/Auth/',

  rootAccount: '/Account/',

  rootTraking: '/Tracking/',

  rootRentalStation: '/Rent/',

  rootPoi: '/POI/',

  rootTouristPoi: '/touristpoi/',

  rootPromo: '/Promo/',

  rootRoute: '/Route/',

  rootProperty: '/RouteProperty/',

  rootPoiProperty: '/PoiProperty/',

  rootFeedback: '/Feedback/',

  mapbox:
  {
    accessToken: 'pk.eyJ1IjoicnVsZXRlY2giLCJhIjoiY2sxdDFycG9sMDBsZzNoa2Jyc2syMXA5YyJ9.ZJt2xJzo0zn7KOalOoSQCQ',
    rootMapbox: 'https://api.mapbox.com/directions/v5/mapbox/'
  },
  appTitle: 'Percorsi Dev',

  features:{
    rent: true,
  }
};
