import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { PoiService } from '../../../../services/POI/poi.service';
import { TranslateService } from '@ngx-translate/core';
import { IFacility } from '../../../../interface/facility';

import { AddFacilityDialogComponent } from '../../../../dialog/admin/add-facility-dialog/add-facility-dialog.component';
import { DetailFacilityDialogComponent } from '../../../../dialog/admin/detail-facility-dialog/detail-facility-dialog.component';
import { EditFacilityDialogComponent } from '../../../../dialog/admin/edit-facility-dialog/edit-facility-dialog.component';
import { DeleteFacilityDialogComponent } from '../../../../dialog/admin/delete-facility-dialog/delete-facility-dialog.component';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../../services/loading/loading.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-rental-station',
  templateUrl: './rental-station.component.html',
  styleUrls: ['./rental-station.component.scss']
})
export class RentalStationComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['name', 'address', 'city', 'email', 'phone', 'details', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>;
  isLoading: boolean;
  isLoadingSubscription: Subscription;

  currentLang: string;
  pageIndex: number = 0;
  pageSize: number = 10;
  dataSort: number = 0;
  orderType: boolean = false;
  totalCount: number = 0;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private POIService: PoiService,
    private loadingService: LoadingService,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource();
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.populateTable();
    this.dataSource.sort = this.sort;
  }

  populateTable() {
    this.isLoading = true;
    this.getFacilities('Rental', this.pageIndex, this.pageSize, this.dataSort, this.orderType);
  }

  getFacilities(type: string, pageNumber: number, pageSize: number, dataSort: number, orderType: boolean) {
    this.isLoading = true;
    this.POIService.getFacilities(type, pageNumber, pageSize, dataSort, orderType).subscribe(res => {
      let facilities: Array<IFacility> = [];
      this.totalCount = res.totalCount;
      facilities = res.result
      this.dataSource.data = facilities;
      this.isLoading = false;
    });
  }

  changePage(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getFacilities('Rental', this.pageIndex, this.pageSize, this.dataSort, this.orderType);
  }

  sortTable(event) {
    this.orderType = event.direction === 'asc' ? true : false;
    switch (event.active) {
      case 'name': {
        this.dataSort = 1;
        this.getFacilities('Rental', this.pageIndex, this.pageSize, this.dataSort, this.orderType);
        break;
      }
      case 'address': {
        this.dataSort = 2;
        this.getFacilities('Rental', this.pageIndex, this.pageSize, this.dataSort, this.orderType);
        break;
      }
      case 'city': {
        this.dataSort = 3;
        this.getFacilities('Rental', this.pageIndex, this.pageSize, this.dataSort, this.orderType);
        break;
      }
      case 'email': {
        this.dataSort = 4;
        this.getFacilities('Rental', this.pageIndex, this.pageSize, this.dataSort, this.orderType);
        break;
      }
    }
  }

  //Open add dialog facility
  openAddFacilityDialog() {
    const dialogRef = this.dialog.open(AddFacilityDialogComponent, {
      width: '600px',
      data: 'Rental',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  //Open details dialog facility
  openDetailsFacilityDialog(facility: IFacility) {
    const dialogRef = this.dialog.open(DetailFacilityDialogComponent, {
      width: '600px',
      data: facility,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  //Open edit dialog facility
  openEditDialog(idFacility: number) {
    const facility = { 'type': 'Rental', 'id': idFacility };
    const dialogRef = this.dialog.open(EditFacilityDialogComponent, {
      width: '600px',
      data: facility,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  //Open delete dialog facility
  openDeleteDialog(facility: IFacility) {
    const dialogRef = this.dialog.open(DeleteFacilityDialogComponent, {
      width: '600px',
      data: facility,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }

}
