import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordGuard implements CanActivate {
  constructor(private enableRoute: ActivatedRoute, private router:Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const userId = state.root.queryParamMap.get('userId');
    const token = state.root.queryParamMap.get('token');
    if (userId && token) {
      return true;
    } else {
      this.router.navigate(['welcome']);
      return false;
    }

   
  }
  
}
