import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ICategory } from '../../interface/category';
import { IThematicArea } from '../../interface/thematic-area';

@Injectable({
  providedIn: 'root'
})
export class RoutePropertyService {

  url: string;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootProperty;
  }

  getCategories(filter: string): Observable<any> {
    if (filter == null) {
      return this.httpClient.get<any>(this.url + 'categories');
    } else {
      return this.httpClient.get<any>(this.url + `categories?filter=${filter}`);
    }
  }

  getCategory(idCategory: number): Observable<ICategory> {
    return this.httpClient.get<ICategory>(this.url + 'category-details' + `?idCategory=${idCategory}`);
  }

  categoryUpsert(category: any) {
    return this.httpClient.post(this.url + 'category-upsert', category);
  }

  deleteCategory(idCategory: number) {
    return this.httpClient.delete(this.url + 'category-delete' + `?idCategory=${idCategory}`);
  }

  getAreas(filter: string): Observable<any> {
    if (filter == null) {
      return this.httpClient.get<any>(this.url + 'typologies');
    } else {
      return this.httpClient.get<any>(this.url + `typologies?filter=${filter}`);
    }
  }

  getArea(id: number): Observable<IThematicArea> {
    return this.httpClient.get<IThematicArea>(this.url + 'typology-details' + `?id=${id}`);
  }

  areasUpsert(area: any) {
    return this.httpClient.post(this.url + 'typology-upsert', area);
  }

  deleteArea(id: number) {
    return this.httpClient.delete(this.url + 'typology-delete' + `?id=${id}`);
  }

  categoryAutoComplete(searchvalue: string): Observable<any> {
    return this.httpClient.get<any>(this.url + 'autocompletecategories' + `?searchvalue=${searchvalue}`);
  }

  areaAutoComplete(searchvalue: string): Observable<any> {
    return this.httpClient.get<any>(this.url + 'autocompletetypologies' + `?searchvalue=${searchvalue}`);
  }

  getTypologies() {
    return this.httpClient.get<any>(this.url + 'typologies');
  }

}
