import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { RentalStationService } from '../../../services/rental-station/rental-station.service';
import { PoiService } from '../../../services/POI/poi.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { IRent } from '../../../interface/rent';
import { switchMap } from 'rxjs/operators';
import { IFacility } from '../../../interface/facility';
import { LoadingService } from '../../../services/loading/loading.service';

@Component({
  selector: 'app-detail-rent-dialog',
  templateUrl: './detail-rent-dialog.component.html',
  styleUrls: ['./detail-rent-dialog.component.scss']
})
export class DetailRentDialogComponent implements OnInit, OnDestroy {

  idRent: number;
  rent$: Observable<IRent>;
  facility: IFacility;
  currentLanguage: string;
  currentFacilities: Array<any> = [];
  isLoading: boolean;
  isLoadingSubscription: Subscription;

  constructor(
    private cookie: CookieService,
    private rentalStationService: RentalStationService,
    private POIService: PoiService,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<DetailRentDialogComponent>,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.idRent = data;
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.currentLanguage = this.translateService.currentLang;
    this.getDetail();
  }

  getDetail() {
    this.rent$ = this.rentalStationService.getRent(this.idRent);
    this.rent$.pipe(switchMap(rent => this.POIService.getFacilityDetails(rent.rentalStation))).subscribe(facility => this.facility = facility);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  acceptRent(idRent: number) {
    this.isLoading = true;
    this.rentalStationService.acceptRent(idRent).subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Prenotazione Accettata con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right'
      });
      this.closeDialog();
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }

}
