import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RentalStationService } from '../../../services/rental-station/rental-station.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { TranslateService } from '@ngx-translate/core';
import { IRent } from '../../../interface/rent';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../services/loading/loading.service';


@Component({
  selector: 'app-delete-rent-dialog',
  templateUrl: './delete-rent-dialog.component.html',
  styleUrls: ['./delete-rent-dialog.component.scss']
})
export class DeleteRentDialogComponent implements OnInit, OnDestroy {
  rent: IRent;
  isLoading: boolean;
  isLoadingSubscription: Subscription;

  constructor(
    private rentalStationService: RentalStationService,
    private dialogRef: MatDialogRef<DeleteRentDialogComponent>,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
    this.rent = data;
  }

  ngOnInit() { }

  deleteRent() {
    this.isLoading = true;
    this.rentalStationService.deleteRent(this.rent.id).subscribe(res => {
      this.isLoading = false;
      this.translateService.get('RentalDelete_200').subscribe(res =>
        this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
          data: res.title,
          duration: 3000,
          verticalPosition: "top",
          horizontalPosition: 'right'
        }));
      this.closeDialog();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
