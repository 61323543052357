import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { PromoService } from '../../../services/promo/promo.service';
import { AddPromotionDialogComponent } from '../../../dialog/marketing/add-promotion-dialog/add-promotion-dialog.component';
import { DetailPromotionDialogComponent } from '../../../dialog/marketing/detail-promotion-dialog/detail-promotion-dialog.component';
import { EditPromotionDialogComponent } from '../../../dialog/marketing/edit-promotion-dialog/edit-promotion-dialog.component';
import { JoinPromotionDialogComponent } from '../../../dialog/marketing/join-promotion-dialog/join-promotion-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { IPromo } from '../../../interface/promo';
import { DeletePromotionDialogComponent } from '../../../dialog/marketing/delete-promotion-dialog/delete-promotion-dialog.component';
import { tap, switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { SnackBarSuccessComponent } from '../../core/snackBar/snack-bar-success/snack-bar-success.component';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['title', 'url', 'radius', 'start', 'end', 'facility', 'status', 'details', 'edit'];
  dataSource: MatTableDataSource<any>;
  promos: Array<IPromo> = [];
  facilities: Array<any>;
  isLoading: boolean;
  currentLanguage: string;

  pageIndex = 0;
  pageSize = 10;
  dataSort: number = 0;
  orderType: boolean = false;
  totalCount = 0;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private promoService: PromoService,
    private matSnackBar: MatSnackBar
  ) {
    this.dataSource = new MatTableDataSource();
  }


  ngOnInit() {
    this.currentLanguage = this.translateService.currentLang;
    this.populateTable();
  }

  // Call API Promo/promos
  populateTable() {
    this.isLoading = true;
    this.promoService.getPromos(this.pageIndex, this.pageSize, this.dataSort, this.orderType).subscribe(res => {
      this.isLoading = false;
      this.totalCount = res.totalCount;
      this.promos = res.result;
      this.dataSource.data = this.promos;
    }, error => {
      this.isLoading = false;
    });
  }

  getFacility(facilities: Array<any>): Array<any> {
    this.facilities = [];

    facilities.forEach(facility => {
      let langInfos: Array<any> = [];
      if (facility.langInfos.length) {
        if (this.translateService.currentLang === 'gb') {
          langInfos = facility.langInfos.filter(info => info.languageCode == 'en-GB');
          if (!langInfos.length) {
            langInfos = facility.langInfos.filter(info => info.languageCode == 'it-IT');
          }
          this.facilities.push(langInfos[0].name);
        } else {
          langInfos = facility.langInfos.filter(info => info.languageCode == 'it-IT');
          if (!langInfos.length) {
            langInfos = facility.langInfos.filter(info => info.languageCode == 'en-GB');
          }
          this.facilities.push(langInfos[0].name);
        }
      }

    });
    return this.facilities;
  }

  changePage(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.populateTable();
  }

  sortTable(event) {
    this.orderType = event.direction === 'asc' ? true : false;
    switch (event.active) {
      case 'start': {
        this.dataSort = 1;
        this.populateTable();
        break;
      }
      case 'end': {
        this.dataSort = 2;
        this.populateTable();
        break;
      }
      case 'facility': {
        this.dataSort = 3;
        this.populateTable();
        break;
      }
    }
  }

  // Method that search element in table
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  checkStartDate(start: string): boolean {
    const startDate = new Date(start).setHours(0, 0, 0, 0);
    return new Date(startDate) > new Date();
  }

  enabledChange(promotion: IPromo) {
    let upsertPromo: any;
    const disabled: boolean = !promotion.disabled;
    this.promoService.promoDetail(promotion.id).pipe(
      tap(promo => {
        upsertPromo = {
          strutture: promo.facilities,
          langInfos: promo.langInfos,
          start: promo.start,
          end: promo.end,
          leftTimeMin: promo.leftTimeMin,
          id: promo.id,
          disabled: disabled,
          url: promo.url,
          radius: promo.radius,
          showcaseEnd: promo.showcaseEnd
        };
      }),
      switchMap(res => this.promoService.promoUpsert(upsertPromo))
    ).subscribe(res => {
      this.populateTable();
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: `Promozione ${upsertPromo.disabled ? 'Disabilitata' : 'Abilitata'} cons successo`,
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
    });
  }

  // Open add promo dialog
  openAddDialog() {
    const dialogRef = this.dialog.open(AddPromotionDialogComponent, {
      width: '800px',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  // Open detail promo dialog
  openDetailDialog(IdPromo: number) {
    const dialogRef = this.dialog.open(DetailPromotionDialogComponent, {
      width: '600px',
      data: IdPromo,
      disableClose: true,
      autoFocus: false
    });
  }

  // Open edit promo dialog
  openEditDialog(IdPromo: number) {
    const dialogRef = this.dialog.open(EditPromotionDialogComponent, {
      width: '800px',
      data: IdPromo,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  // Open delete promo dialog
  openDeleteDialog(promo: IPromo) {
    const dialogRef = this.dialog.open(DeletePromotionDialogComponent, {
      width: '600px',
      data: promo,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  // Open associate promo dialog
  openJoinDialog(IdPromo: number) {
    const promotionData = IdPromo;
    const dialogRef = this.dialog.open(JoinPromotionDialogComponent, {
      width: '600px',
      data: promotionData,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }


}
