import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { RoutePropertyService } from '../../../../services/route-property/route-property.service';
import { TranslateService } from '@ngx-translate/core';
import { PoiPropertyService } from '../../../../services/poi-property/poi-property.service';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../../services/loading/loading.service';
import { SpaceValidation } from '../../../../validators/space-validator';

@Component({
  selector: 'app-add-category-dialog',
  templateUrl: './add-category-dialog.component.html',
  styleUrls: ['./add-category-dialog.component.scss']
})
export class AddCategoryDialogComponent implements OnInit, OnDestroy {
  upsertForm: FormGroup;
  isLoading: boolean;
  isLoadingSubscription: Subscription;
  categoryReference: string;

  languagesCode: Array<string> = ['it-IT', 'en-GB'];

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private propertyService: RoutePropertyService,
    private poiPropertyService: PoiPropertyService,
    private translateService: TranslateService,
    private matSnackBar: MatSnackBar,
    private loadingService: LoadingService,
    private spaceValidation: SpaceValidation,
  ) {
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
    this.categoryReference = data;
  }

  ngOnInit() {
    this.checkUpsertForm();
  }

  checkUpsertForm() {
    this.upsertForm = this.formBuilder.group({
      name: [null, [Validators.required, Validators.max(255), this.spaceValidation.checkSpaceValidation]],
      code: [null, [Validators.required, this.spaceValidation.checkSpaceValidation]],
      languageCode: [null, Validators.required]
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.upsertForm.invalid)
      return;

    const category = {
      id: 0,
      langInfos: {
        name: this.upsertForm.value.name,
        code: this.upsertForm.value.code,
        languageCode: this.upsertForm.value.languageCode
      }
    };

    if (this.categoryReference === 'route-categories')
      this.upsertRouteCategory(category);
    else
      this.upsertPoiCategory(category);
  }

  upsertRouteCategory(category: any) {
    this.isLoading = true;

    this.propertyService.categoryUpsert(category).subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Categoria aggiunta con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right'
      });
      this.closeDialog();
    });
  }

  upsertPoiCategory(category: any) {
    this.isLoading = true;

    this.poiPropertyService.categoryUpsert(category).subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Categoria aggiunta con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right'
      });
      this.closeDialog();
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
