import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMapboxOutput } from '../../interface/MapboxOutput';

@Injectable({
  providedIn: 'root'
})
export class MapboxService {
  url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.mapbox.rootMapbox;
  }

  getRouteCycling(parameters: string) {
    let access_token = '?steps=true&overview=full&geometries=geojson&access_token=' + environment.mapbox.accessToken;
    return this.httpClient.get(this.url + 'cycling/' + parameters + access_token);
  }

  getRouteWalking(parameters: string) {
    let access_token = '?steps=true&overview=full&geometries=geojson&access_token=' + environment.mapbox.accessToken;
    return this.httpClient.get(this.url + 'walking/' + parameters + access_token);
  }

  getRouteDriving(parameters: string) {
    let access_token = '?steps=true&overview=full&geometries=geojson&access_token=' + environment.mapbox.accessToken;
    return this.httpClient.get(this.url + 'driving/' + parameters + access_token);
  }

  searchPoi(query: string): Observable<any> {
    return this.httpClient.get<any>(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?&access_token=${environment.mapbox.accessToken}`).pipe(
      map((res: IMapboxOutput) => {
        return res.features;
      })
    );
  }
}
