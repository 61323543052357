import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IUser } from '../../interface/user';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url: string;
  clientId: string;
  clientSecret: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootAuth;
    this.clientId = environment.clientId;
    this.clientSecret = environment.clientSecret;
  }

  signIn(email: string, password: string) {
    const body = {
      email,
      password,
      clientId: this.clientId,
      clientSecret: this.clientSecret,
      grantType: 'password'
    };
    return this.httpClient.post(this.url + 'Token', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }

  injectRefreshToken() {
    const accessToken = sessionStorage.getItem('accountToken');
    const refreshToken = sessionStorage.getItem('refreshToken');
    return this.httpClient.post(this.url + 'RefreshToken', { accessToken, refreshToken});
  }

  getUser(): Observable<IUser> {
    const accessToken: string = sessionStorage.getItem('accountToken');
    const headers = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken };
    return this.httpClient.get<IUser>(this.url + 'getuser', { headers });
  }
}
