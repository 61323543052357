import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-snack-bar-error',
  templateUrl: './snack-bar-error.component.html',
  styleUrls: ['./snack-bar-error.component.scss']
})
export class SnackBarErrorComponent implements OnInit {

    constructor(
        public snackBarRef: MatSnackBarRef<SnackBarErrorComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) { }

    ngOnInit()
    { }

}
