import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { PoiService } from '../../../../services/POI/poi.service';
import { PoiPropertyService } from '../../../../services/poi-property/poi-property.service';
import { SnackBarSuccessComponent } from '../../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddCategoryDialogComponent } from '../../category/add-category-dialog/add-category-dialog.component';
import { Observable } from 'rxjs';
import { IPoi } from '../../../../interface/poi';
import { IFeature } from '../../../../interface/feature';
import { MapboxService } from '../../../../services/mapbox/mapbox.service';
import { SpaceValidation } from '../../../../validators/space-validator';

@Component({
  selector: 'app-edit-poi-dialog',
  templateUrl: './edit-poi-dialog.component.html',
  styleUrls: ['./edit-poi-dialog.component.scss']
})
export class EditPoiDialogComponent implements OnInit {

  IdPoi: any;
  poi: IPoi;
  isLinear = false;
  secondFormGroup: FormGroup;
  sheetForm: FormGroup;
  technicalForm: FormGroup;
  thirdFormGroup: FormGroup;

  feature: IFeature;
  feautures: Array<IFeature> = [];
  lat: any;
  lng: any;

  typePoint: string = null;
  attachments: Array<any> = [];
  descriptionTouristPoi: any;

  categories$: Observable<any>;

  constructor(
    private dialogRef: MatDialogRef<EditPoiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private formBuilder: FormBuilder,
    private poiService: PoiService,
    private mapboxService: MapboxService,
    private dialog: MatDialog,
    private poiPropertyService: PoiPropertyService,
    private matSnackBar: MatSnackBar,
    private spaceValidation: SpaceValidation
  ) {
    this.IdPoi = data;
  }

  ngOnInit() {
    this.getPOI();
  }

  getPOI() {
    this.poiService.getDetailpoi(this.IdPoi).subscribe(res => {
      this.poi = res;
      console.log(this.poi);
      if (this.poi.type === 'Technical') {
        this.checktechnicalForm();
      } else {
        this.checkSecondForm();
        this.checkSheetInfoForm();
        this.checkThirdForm();
      }
    });
  }

  // Search place
  search(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    if (searchTerm && searchTerm.length >= 3) {
      this.feature = null;
      this.mapboxService.searchPoi(searchTerm).subscribe(res => {
        this.feautures = [];
        res.map(feature => {
          this.feautures.push({
            place_name: feature.place_name,
            city: '',
            latitude: feature.geometry.coordinates[1],
            longitude: feature.geometry.coordinates[0]
          });
        });
      });
    } else {
      this.feautures = [];
    }
  }

  // Select place
  addressesSelected(optionSelected: string) {
    const address: string = optionSelected;
    this.feautures.forEach(feature => {
      if (feature.place_name === address) {
        this.feature = feature;
      }
    });
    this.lat = this.feature.latitude;
    this.lng = this.feature.longitude;
    this.secondFormGroup.get('latitude').setValue(this.lat);
    this.secondFormGroup.get('longitude').setValue(this.lng);
  }

  // Insert Techinical Poi
  checktechnicalForm() {
    this.technicalForm = this.formBuilder.group({
      language: [''],
      infos: this.formBuilder.array([]),
      city: [this.poi.city],
      disabled: [this.poi.disabled],
      latitude: [this.poi.latitude, Validators.required],
      longitude: [this.poi.longitude, Validators.required],
    });

    this.checkLanguageInfoForm();
  }

  get infosForms() {
    return this.technicalForm.get('infos') as FormArray;
  }

  getTechnicalForm() {
    const value = this.technicalForm.value;
    const currentPoi = {
      latitude: value.latitude,
      longitude: value.longitude,
      id: this.poi.id,
      langInfos: value.infos,
      city: value.city,
      disabled: value.disabled,
      actionRadius: 0,
      tolerance: 0
    };
    this.editTechnicalPoi(currentPoi);
  }

  // API upsert technical POI
  editTechnicalPoi(currentPoi: any) {
    this.poiService.upsertTechnicalPoi(currentPoi).subscribe(res => {
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'modifica del Poi Tecnico riuscita con successo',
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.dialogRef.close(res);
    }, error => {
    });
  }
  // End Insert Techinical Poi

  // Insert Tourist Poi
  checkSecondForm() {
    this.secondFormGroup = this.formBuilder.group({
      language: [''],
      infos: this.formBuilder.array([]),
      city: [this.poi.city],
      disabled: [this.poi.disabled],
      latitude: [this.poi.latitude, Validators.required],
      longitude: [this.poi.longitude, Validators.required],
      category: [this.poi.category]
    });

    this.checkLanguageInfoForm();
  }

  checkSheetInfoForm() {
    this.sheetForm = this.formBuilder.group({
      url: [this.poi.sheet ? this.poi.sheet.url : null, [Validators.pattern('^(ftp|http|https):\/\/[^ "]+$')]],
      language: [null],
      infos: this.formBuilder.array([]),
    });
    if (this.poi.sheet) {
      this.checkSheetlanguage();
    }
  }

  applyFilterCategory(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.categories$ = this.poiPropertyService.categoryAutoComplete(filterValue);
  }

  displayCategory(category: any): string {
    return category && category.name ? category.name : '';
  }

  openAddCategoryDialog() {
    const dialogRef = this.dialog.open(AddCategoryDialogComponent, {
      width: '650px',
      data: 'poi-categories',
      disableClose: true,
      autoFocus: false
    });
  }

  checkThirdForm() {
    this.thirdFormGroup = this.formBuilder.group({
      contents: this.formBuilder.array([]),
    });
    if (this.poi.sheet) {
      this.checkContentsForm();
    }
  }

  get infosFormsTourist() {
    return this.secondFormGroup.get('infos') as FormArray;
  }

  getValueFromSecondForm() {
    this.descriptionTouristPoi = this.secondFormGroup.value;
  }

  get sheetInfoPoi() {
    return this.sheetForm.get('infos') as FormArray;
  }

  getValueFromSheetForm() { }

  get multimediaContents() {
    return this.thirdFormGroup.get('contents') as FormArray;
  }

  getContentLanguage(content: any) {
    return content.controls.infos.controls;
  }

  fileChangeEvent(fileInput, fileType: string) {
    const file = fileInput.target.files[0];
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.poiService.uploadMediaFile(file, 'Geolocation').subscribe(res => {
      this.attachments.push({
        id: 0,
        fileName: res,
        type: fileType,
        langInfos: []
      });

      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'File caricato con successo',
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });

      const content = this.formBuilder.group({
        file: [file],
        name: [res],
        type: [fileType],
        language: [null, Validators.required],
        infos: this.formBuilder.array([])
      });
      this.multimediaContents.push(content);
    }, error => { });


  }

  checkContentsForm() {
    if (this.poi.sheet.attachments.length > 0) {
      this.poi.sheet.attachments.forEach((currentContent, index) => {
        const content = this.formBuilder.group({
          file: [null],
          name: [currentContent.fileName],
          type: [currentContent.type],
          language: [null],
          infos: this.formBuilder.array([])
        });
        this.multimediaContents.push(content);
        this.checkInfosLanguageContent(currentContent.langInfos, index);

        this.attachments.push({
          id: currentContent.id,
          fileName: currentContent.fileName,
          type: currentContent.type,
          langInfos: currentContent.langInfos
        });
      });
    }
  }

  checkInfosLanguageContent(langInfos: Array<any>, indexContent: number) {
    const infos = this.multimediaContents.controls[indexContent].get('infos') as FormArray;
    langInfos.forEach(currentInfo => {
      const info = this.formBuilder.group({
        languageCode: [currentInfo.languageCode],
        description: [currentInfo.description, [Validators.required, this.spaceValidation.checkSpaceValidation]]
      });
      infos.push(info);
    });
  }

  removeFile(indexContent: number) {
    this.multimediaContents.removeAt(indexContent);
    this.attachments.splice(indexContent, 1);
  }

  getValueFromThirdForm() {
    this.thirdFormGroup.value.contents.forEach((content, index) => {
      if (content.name == this.attachments[index].fileName) {
        this.attachments[index].langInfos = content.infos;
      }
    });
    const touristPoi = {
      sheet: {
        id: this.poi.sheet ? this.poi.sheet.id : 0,
        url: this.sheetForm.value.url,
        attachments: this.attachments,
        langInfos: this.sheetForm.value.infos
      },
      category: this.secondFormGroup.value.category,
      latitude: this.secondFormGroup.value.latitude,
      longitude: this.secondFormGroup.value.longitude,
      id: this.poi.id,
      langInfos: this.secondFormGroup.value.infos,
      city: this.secondFormGroup.value.city,
      disabled: this.secondFormGroup.value.disabled,
      actionRadius: this.poi.actionRadius,
      tolerance: this.poi.tolerance
    };
    this.editTouristPoi(touristPoi);
  }

  editTouristPoi(touristPoi: any) {
    this.poiService.upsertTouristPoi(touristPoi).subscribe(res => {
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Modifica del Poi Turistico riuscita con successo',
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.dialogRef.close(res);
    }, error => {});
  }

  // SelectLangInfosForm
  onChangeLanguages(language: string, type: string) {
    let typeForm: any;

    if (type === 'Technical') {
      typeForm = this.infosForms;
      this.technicalForm.get('language').clearValidators();
      this.technicalForm.get('language').updateValueAndValidity();
      this.technicalForm.get('language').reset();
    }

    if (type === 'Tourist') {
      typeForm = this.infosFormsTourist;
      this.secondFormGroup.get('language').clearValidators();
      this.secondFormGroup.get('language').updateValueAndValidity();
      this.secondFormGroup.get('language').reset();
    }

    this.fillInfoArray(language, typeForm);

  }

  checkLanguageInfoForm() {
    let typeForm: any;

    if (this.poi.type === 'Technical') {
      typeForm = this.infosForms;
    }
    if (this.poi.type === 'Tourist') {
      typeForm = this.infosFormsTourist;
    }
    this.poi.langInfos.forEach(currentInfo => {
      const info = this.formBuilder.group({
        languageCode: [currentInfo.languageCode],
        name: [currentInfo.name, [Validators.required, this.spaceValidation.checkSpaceValidation]],
        address: [currentInfo.address, [Validators.required, this.spaceValidation.checkSpaceValidation]]
      });
      typeForm.push(info);
    });
  }

  fillInfoArray(language: string, type: any) {
    const info = this.formBuilder.group({
      languageCode: [language],
      name: [null, [Validators.required, this.spaceValidation.checkSpaceValidation]],
      address: [null, [Validators.required, this.spaceValidation.checkSpaceValidation]]
    });

    if (type.length > 0) {
      this.checkIfInfoExist(info, type);
    }
    else {
      type.push(info);
    }
  }

  checkIfInfoExist(info: any, type: any) {
    let find: boolean;

    type.controls.forEach((item) => {
      if (item.get('languageCode').value === info.get('languageCode').value) {
        find = true;
        return;
      }
    });

    if (!find) {
      type.push(info);
    }
  }

  removeLanguage(index: number, type) {
    const typeForm = type == 'Technical' ? this.infosForms : this.infosFormsTourist;
    typeForm.removeAt(index);
  }
  // End SelectLangInfosForm


  // SelectLanguageSheet
  onChangeLanguagesSheet(language: string) {
    this.sheetForm.get('language').clearValidators();
    this.sheetForm.get('language').updateValueAndValidity();
    this.sheetForm.get('language').reset();
    const info = this.formBuilder.group({
      languageCode: [language],
      title: [null, [Validators.required, this.spaceValidation.checkSpaceValidation]],
      description: [null, [Validators.required, this.spaceValidation.checkSpaceValidation]]
    });

    if (this.sheetInfoPoi.length > 0) {
      this.checkIfInfoSheetExist(info);
    } else {
      this.sheetInfoPoi.push(info);
    }
  }

  checkSheetlanguage() {
    this.poi.sheet.langInfos.forEach(currentInfo => {
      const info = this.formBuilder.group({
        languageCode: [currentInfo.languageCode],
        title: [currentInfo.title, [Validators.required, this.spaceValidation.checkSpaceValidation]],
        description: [currentInfo.description, [Validators.required, this.spaceValidation.checkSpaceValidation]]
      });
      this.sheetInfoPoi.push(info);
    });
  }

  checkIfInfoSheetExist(info: any) {
    let find: boolean;

    this.sheetInfoPoi.controls.forEach((item) => {
      if (item.get('languageCode').value === info.get('languageCode').value) {
        find = true;
        return;
      }
    });

    if (!find) {
      this.sheetInfoPoi.push(info);
    }
  }

  removeInfoSheet(index: number) {
    this.sheetInfoPoi.removeAt(index);
  }
  // End SelectLanguageSheet


  // SelectLanguageContent
  onChangeLanguagesContent(language: string, indexContent: number) {
    this.multimediaContents.controls[indexContent].get('language').clearValidators();
    this.multimediaContents.controls[indexContent].get('language').updateValueAndValidity();
    this.multimediaContents.controls[indexContent].get('language').reset();
    const infos = this.multimediaContents.controls[indexContent].get('infos') as FormArray;
    const info = this.formBuilder.group({
      languageCode: [language],
      description: [null, [Validators.required, this.spaceValidation.checkSpaceValidation]]
    });

    if (infos.length > 0) {
      this.checkIfInfoMultimediaExist(info, indexContent);
    }
    else {
      infos.push(info);
    }
  }

  checkIfInfoMultimediaExist(info: any, indexContent: number) {
    const infos = this.multimediaContents.controls[indexContent].get('infos') as FormArray;
    let find: boolean;

    infos.controls.forEach((item) => {
      if (item.get('languageCode').value === info.get('languageCode').value) {
        find = true;
        return;
      }
    });

    if (!find) {
      infos.push(info);
    }
  }

  removeInfoMultimedia(index: number, indexContent: number) {
    const infos = this.multimediaContents.controls[indexContent].get('infos') as FormArray;
    infos.removeAt(index);
  }
  // End SelectLanguageContent

  // Method that closed dialog
  closeDialog() {
    this.dialogRef.close();
  }


}
